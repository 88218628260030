import {
    GLOBAL_CONSTANTS
} from '../utils/constants'

import Emitter from '../utils/emitter'

const SELECTORS = {
    COMPONENT: '.js-link-list',
    LIST: '.js-list',
    LINK_ITEM: '.js-link-item',
    DRAWER: '.js-drawer',
    DRAWER_CONTENT: '.js-drawer-content',
    DRAWER_CLOSE: '.js-drawer-close',
    DRAWER_SCRIM: '.js-drawer-scrim'
}


export default class LinkList {
    /**
     * @desc Set up Link List with elements and bind events.
     * @param {HTMLElement} el - Container element for Link List
     *
     */

    constructor(element) {
        this.element = element
        this.drawerMap = {}
        this.activeInstance = null

        this.linkList = this.element.querySelector(SELECTORS.LIST)
        this.linkItems = this.element.querySelectorAll(SELECTORS.LINK_ITEM)

        this.drawerEl = this.element.querySelector(SELECTORS.DRAWER)
        this.drawerCloseEl = this.element.querySelector(SELECTORS.DRAWER_CLOSE)
        this.drawerScrim = this.element.querySelector(SELECTORS.DRAWER_SCRIM)

        this.toggleActive = this.toggleActive.bind(this)
        this.onMouseEvent = this.onMouseEvent.bind(this)
        this.closeDrawer = this.closeDrawer.bind(this)

        this.registerListeners()
        this.generateMap()
    }

    registerListeners() {
        this.drawerCloseEl.addEventListener('click', this.closeDrawer)
        this.drawerScrim.addEventListener('click', this.closeDrawer)

        Emitter.on('keydown', (event) => {
            if (this.activeInstance) {
                this.trapFocus(event)
            }

            if (event.which === GLOBAL_CONSTANTS.KEYS.ESC && this.activeInstance) {
                this.closeDrawer()
            }

        })
    }

    generateMap() {

        this.linkItems.forEach(item => {

            item.addEventListener('click', this.toggleActive)
            item.addEventListener('mouseover', this.onMouseEvent)
            item.addEventListener('mouseout', this.onMouseEvent)

            const target = item.dataset.target
            const drawerPanel = this.drawerEl.querySelector(`[data-id="${target}"]`)

            this.drawerMap[target] = {
                el: item,
                target,
                drawerPanel,
                ...this.registerFocusableElements(drawerPanel)
            }
        })

    }

    onMouseEvent(event) {
        event.target.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HOVER)
        this.linkList.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HOVER)
    }

    toggleActive(event) {
        event.preventDefault()

        const target = event.target.dataset.target

        this.activeInstance = this.drawerMap[target]
        this.activeInstance.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.activeInstance.drawerPanel.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        this.linkList.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        this.openDrawer(event.target.dataset.target)

        this.activeInstance.firstFocusableEl.focus()

    }

    openDrawer() {
        this.drawerEl.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.drawerEl.removeAttribute("inert")
    }

    closeDrawer(e) {
        if (e) {
            e.preventDefault()
        }

        this.drawerEl.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.drawerEl.setAttribute("inert", '')
        this.linkList.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        this.activeInstance.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.activeInstance.drawerPanel.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        delete this.activeInstance
    }

    /*
    ** Query our select node/wrappers for focusable elements. Determine first/last and return.
    */
    registerFocusableElements(node) {
        const focusableEls = [
            this.drawerCloseEl,
            ...node.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select')
        ]
        const firstFocusableEl = focusableEls[0]
        const lastFocusableEl = focusableEls[focusableEls.length - 1]
        return {
            focusableEls,
            firstFocusableEl,
            lastFocusableEl
        }
    }

    trapFocus(event) {
        const isTabPressed = (event.key === 'Tab' || event.keyCode === 9)
        if (!isTabPressed) {
            return
        }
        if ( event.shiftKey ) /* shift + tab */ {
            if (document.activeElement === this.activeInstance.firstFocusableEl) {
                this.activeInstance.lastFocusableEl.focus()
                event.preventDefault()
            }
        } else /* tab */ {
            if (document.activeElement === this.activeInstance.lastFocusableEl) {
                this.activeInstance.firstFocusableEl.focus()
                event.preventDefault()
            }
        }
    }

}




/**
 * @desc Test component definition used in module-loader
 */

export const LinkListComponent = {
    'name': 'LinkList',
    'class': SELECTORS.COMPONENT,
    'Source': LinkList
}