import { GLOBAL_CONSTANTS } from './constants'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

export default function initLazyLoad() {
    document.addEventListener('lazyloaded', e => {
        const img = e.target
        imageLoaded(img)
    })
}

/**
 * @desc Display the image and its parent container by adding a class that toggles opacity
 * The timeout is required here for the animation to work correctly.
 * @param {HTMLElement} img - The image that is in the viewport
 */
function imageLoaded(img) {
    setTimeout(() => {
        img.classList.add(GLOBAL_CONSTANTS.CLASSES.LOADED)
        const parentElement = img.closest('.js-wait-for-load')
        if (parentElement) {
            parentElement.classList.add(GLOBAL_CONSTANTS.CLASSES.LOADED)

            const grandParentElement = parentElement.closest('.js-wait-for-load-parent')
            if (grandParentElement) {
                grandParentElement.classList.add(GLOBAL_CONSTANTS.CLASSES.LOADED)
            }
        }
    }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
}

