import Emitter from '../utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import { isDesktop } from '../utils/windowResizeUtil'
import { TweenMax, Power3 } from 'gsap/all'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { NavIndexListComponent } from './NavIndexList'

/**
 * Makes the nav show / hide based on
 * scroll position / direction.
 */

const SELECTORS = {
    COMPONENT: '.js-navigation',
    NAV_LINK: '.js-nav-menu-link',
    NAV_LI: '.js-nav-li',
    NAV_ITEM: '.js-nav-item',
    NAV_CLOSE: '.js-navigation-close-button',
    NAV_MENUS: '.js-nav-menus',
    NAV_MENU: '.js-nav-menu',
    NAV_ACTIVE: 'nav-active',
    HEADER_ITEM: '.js-header-item',
    SEARCH_ICON: '.js-search-icon',
    SEARCH_WRAPPER: '.js-search-wrapper',
    SEARCH_HOLDER: '.js-search-holder',
    LOGO: '.js-header-logo',
    NAV_CONTENT_ITEMS: '.js-nav-content-item',
    SEARCH_SCRIM: '.js-search-scrim',
    CENTER_NAV_HOLDER: '.js-nav-center-area',
    DYNAMIC_CONTENT_AREA: '.js-nav-dynamic-content',
    PAGE_TITLE_BAR: '.js-title-bar',
    NAV_RANDOM_ARCHIVE_IMAGE: '.js-nav-content-random-image',
    LANG_CHOOSER: '.js-language-chooser'
}

export default class Nav {
    /**
     * @desc Set up nav with elements and bind events.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */

    constructor() {
        this.el = document.querySelector(SELECTORS.COMPONENT)

        this.navMenuToggle = this.el.querySelector(SELECTORS.NAV_LINK)
        this.navClose = this.el.querySelector(SELECTORS.NAV_CLOSE)
        this.navMenu = this.el.querySelector(SELECTORS.NAV_MENU)
        this.navMenus = this.el.querySelector(SELECTORS.NAV_MENUS)
        this.navListItems = Array.from(this.el.querySelectorAll(SELECTORS.NAV_LI))
        this.navItems = Array.from(this.el.querySelectorAll(SELECTORS.NAV_ITEM))

        this.randomArchiveImages = this.el.querySelectorAll(SELECTORS.NAV_RANDOM_ARCHIVE_IMAGE)

        this.navIndexListItems = this.el.querySelectorAll(NavIndexListComponent.class)

        this.allLinks = Array.from(this.el.getElementsByTagName('A'))
        this.headerItems = Array.from(this.el.querySelectorAll(SELECTORS.HEADER_ITEM))
        this.logo = this.el.querySelector(SELECTORS.LOGO)
        this.contentItems = this.el.querySelectorAll(SELECTORS.NAV_CONTENT_ITEMS)

        this.searchIcon = this.el.querySelector(SELECTORS.SEARCH_ICON)
        this.searchWrapper = this.el.querySelector(SELECTORS.SEARCH_WRAPPER)
        this.searchHolder = this.el.querySelector(SELECTORS.SEARCH_HOLDER)
        this.searchScrim = document.querySelector(SELECTORS.SEARCH_SCRIM)

        this.centerNavHolder = document.querySelector(SELECTORS.CENTER_NAV_HOLDER)
        this.dynamicContentArea = document.querySelector(SELECTORS.DYNAMIC_CONTENT_AREA)
        this.languageChooser = document.querySelector(SELECTORS.LANG_CHOOSER)


        this.isDesktop = isDesktop()
        this.isDynamicContentVisible = false

        this.activeNavPanel = null

        this.showNavThreshold = GLOBAL_CONSTANTS.SIZES.ARTIST_NAV_THRESHOLD
        this.scrollThreshold = GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP
        this.currentScrollPosition = 0
        this.previousScrollPosition = 0

        this.contentItemsInstanceMap = {}
        this.linkItemsInstanceMap = {}
        this.navIndexListInstances = []
        this.tabItems = []
        this.animationItems = [this.navClose, ...this.navListItems]


        this.throttleScroll = null
        this.handleMenuKeyDown = this.handleMenuKeyDown.bind(this)
        this.handleCloseClicked = this.handleCloseClicked.bind(this)
        this.toggleNavMenu = this.toggleNavMenu.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.onMouseEvent = this.onMouseEvent.bind(this)
        this.onItemClick = this.onItemClick.bind(this)

        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.generateLinkItemsMap()
        this.generateContentItemsMap()
        this.generateIndexLists()
        this.setRandomArchiveImage()

        this.registerEvents()
    }

    /**
     * @desc Listen for the global scroll event.
     */
    registerEvents() {
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)

        this.throttleResize = throttle(GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE, this.handleResize.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.throttleResize)

        if (this.searchIcon) {
            this.searchIcon.addEventListener('click', this.toggleSearchBar)
        }
        this.searchScrim.addEventListener('click', this.toggleSearchBar)

        // Close menu when history changes via forward/back buttons.
        window.onpopstate = () => {
            this.closeNavMenu()
        }

        this.navMenuToggle.addEventListener('click', this.toggleNavMenu)
        this.navClose.addEventListener('click', this.handleCloseClicked)

        this.navItems.forEach(item => {
            item.addEventListener('mouseover', this.onMouseEvent)
            item.addEventListener('mouseout', this.onMouseEvent)
        })

        this.allLinks.forEach(item => {
            item.addEventListener('click', this.onItemClick)
        })

        this.initDynamicContentArea()
    }

    generateIndexLists() {
        this.navIndexListItems.forEach(indexList => {
            this.navIndexListInstances.push(new NavIndexListComponent.Source(indexList))
        })
    }

    /**
     * @desc Create linkItemsInstanceMap object from the nav links.
     */
    generateLinkItemsMap() {
        this.navItems.forEach(item => {
            if (item.dataset.panelContent) {
                this.linkItemsInstanceMap[item.dataset.panelContent] = {
                    el: item,
                    current: false
                }
            }
        })
    }

    /**
     * @desc Create contentItemsInstanceMap object from the content panels.
     */
    generateContentItemsMap() {
        this.contentItems.forEach(item => {

            const dataObject = {
                el: item,
                active: false,
                type: item.dataset.panelType,
                tabItems: Array.from(item.getElementsByTagName('A'))
            }

            this.contentItemsInstanceMap[dataObject.type] = dataObject

        })
    }

    setRandomArchiveImage() {
        if (this.randomArchiveImages.length) {
            const item = this.randomArchiveImages[Math.floor(Math.random() * this.randomArchiveImages.length)]
            item.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        }
    }

    updateLinkVisibility(target) {
        let hasCurrent = false
        for (let key in this.linkItemsInstanceMap) {
            const item = this.linkItemsInstanceMap[key]

            if (key === target) {
                item.el.classList.add(GLOBAL_CONSTANTS.CLASSES.CURRENT)
                item.current = true
                hasCurrent = true
            } else {
                item.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.CURRENT)
                item.current = false
            }
        }

        if (hasCurrent) {
            this.navMenus.classList.add(GLOBAL_CONSTANTS.CLASSES.HAS_CURRENT)
        } else {
            this.navMenus.classList.remove(GLOBAL_CONSTANTS.CLASSES.HAS_CURRENT)
        }
    }

    updateContentVisibility(target = this.activeNavPanel) {
        this.previousNavPanel = this.activeNavPanel
        this.activeNavPanel = target


        if (this.activeNavPanel) {
            this.activeNavPanel.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.activeNavPanel.active = true

            // Update our tab item list with links in the current open tab
            this.tabItems.push(...this.activeNavPanel.tabItems)
            if (this.activeNavPanel.tabItems.length) {
                this.activeNavPanel.tabItems[0].focus()
            }

            setTimeout(() => {
                this.activeNavPanel.el.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOWING)
            }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
        }

        if (this.previousNavPanel && this.previousNavPanel.type !== this.activeNavPanel.type) {
            this.previousNavPanel.active = false
            this.previousNavPanel.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.SHOWING)
            setTimeout(() => {
                this.previousNavPanel.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
        }

    }

    handleResize() {
        this.isDesktop = isDesktop()
        if (isDesktop && this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)) {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
        }
    }

    initDynamicContentArea() {
        const pageTitleBarContent = document.querySelector(SELECTORS.PAGE_TITLE_BAR)
        if (pageTitleBarContent) {
            this.dynamicContentArea.appendChild(pageTitleBarContent)
        }
    }

    openSearchBar() {
        document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        this.searchScrim.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        this.searchWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        const searchHeight = this.searchWrapper.offsetHeight
        this.searchHolder.style.height = `${searchHeight}px`
        this.searchIcon.setAttribute('aria-expanded', 'true')

        const searchInput = this.searchWrapper.querySelector("input[type=text]")
        if (searchInput) {
            searchInput.focus()
        }
    }

    closeSearchBar() {
        this.searchScrim.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        this.searchTimeout = setTimeout(() => {
            this.searchWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            document.body.classList.remove(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)
        }, GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)

        this.searchHolder.style.height = '0px'
        this.searchIcon.setAttribute('aria-expanded', 'false')
    }

    toggleSearchBar(event) {
        event.preventDefault()
        clearTimeout(this.searchTimeout)

        if (!document.body.classList.contains(GLOBAL_CONSTANTS.CLASSES.SEARCH_ACTIVE)) {
            this.openSearchBar()
        } else {
            this.closeSearchBar()
        }
    }

    onItemClick(event) {
        const panelTarget = this.contentItemsInstanceMap[event.target.dataset.panelContent]

        if (this.isDesktop && panelTarget) {
            event.preventDefault()
            event.stopPropagation()
            this.updateContentVisibility(panelTarget)
            this.updateLinkVisibility(panelTarget.type)
        } else {
            // For links that don't control a content panel,
            // just follow the href and close the nav-menu.
            this.closeNavMenu()
        }

    }

    onMouseEvent(event) {
        const itemLink = event.target
        itemLink.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        if (itemLink.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            // Hovering on a link.
            this.navMenus.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            // Stop hover action.
            this.navMenus.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }

    /**
     * @desc Handle key down for menu to set proper focus
     */
    handleMenuKeyDown(e) {
        const keyCode = e.keyCode || e.which

        if (document.body.classList.contains(SELECTORS.NAV_ACTIVE) && keyCode === 27) { // Handles escape key
            this.closeNavMenu()
            this.navMenuToggle.focus()
        } else if (e.target === this.tabItems[this.tabItems.length - 1] && !e.shiftKey && keyCode === 9) { // Handles tabbing past the last menu item to return to menu toggle button
            event.preventDefault()
            this.tabItems[0].focus()
        } else if (e.target === this.tabItems[0] && e.shiftKey && keyCode === 9) { // Handles shift-tabbing past the first menu item
            event.preventDefault()
            this.tabItems[this.tabItems.length - 1].focus()
        } else if (keyCode === 32) { // Handles spacebar for click
            e.target.click()
        }
    }

    /**
     * @desc Handles orientation change by firing our handleScroll function
     */
    handleOrientationChange() {
        this.handleScroll(true)
    }

    /**
     * @desc Scroll event that determines if the nav should show or hide.
     */
    handleScroll(hasOrientationChanged = false) {
        if (hasOrientationChanged) {
            this.setActive(false)
            return
        }

        this.currentScrollPosition = window.pageYOffset

        if (this.currentScrollPosition < this.scrollThreshold) {
            if (this.languageChooser) {
                this.languageChooser.classList.remove('-is-scrolled')
            }

            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            if (this.languageChooser) {
                this.languageChooser.classList.add('-is-scrolled')
            }

            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }

        // Handle mobile shy nav
        if (!this.isDesktop) {

            if (this.currentScrollPosition > this.scrollThreshold) {

                if (this.el.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE) &&
                    this.currentScrollPosition > this.previousScrollPosition
                ) {
                    this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
                    this.closeSearchBar()
                } else {
                    this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
                }

            } else {
                this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
            }
        }


        if (this.isDesktop) {

            if (this.currentScrollPosition > this.showNavThreshold && this.dynamicContentArea.innerHTML !== '') {
                if (!this.isDynamicContentVisible) {
                    this.centerNavHolder.classList.add('-showing-dynamic-content')
                    this.isDynamicContentVisible = true
                }
            } else {
                if (this.isDynamicContentVisible) {
                    this.centerNavHolder.classList.remove('-showing-dynamic-content')
                    this.isDynamicContentVisible = false
                }
            }
        }

        this.previousScrollPosition = this.currentScrollPosition

    }

    openNavMenu() {
        disableBodyScroll(this.navMenu)
        this.tabItems = [this.navClose, ...this.navItems]

        Emitter.on('keydown', this.handleMenuKeyDown)
        document.body.classList.add(SELECTORS.NAV_ACTIVE)
        this.searchScrim.classList.add('open-scrim')

        this.navMenuToggle.setAttribute('aria-expanded', true)
        this.navMenuToggle.setAttribute('aria-label', 'Close Menu')
        this.navMenu.setAttribute('aria-hidden', false)


        setTimeout(() => {
            TweenMax.staggerFromTo(
                this.animationItems,
                0.35,
                { opacity: 0, y: 50, ease: Power3.easeOut },
                { opacity: 1, y: 0, ease: Power3.easeOut },
                0.05, showScrollBar
            )


        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)

        //Display navigation scrollbar when animation is complete
        function showScrollBar() {
            document.querySelector('.navigation__menu-wrapper').style.overflow = "auto"
        }

        setTimeout(function () {
            document.body.classList.contains(SELECTORS.NAV_ACTIVE)
        }, 800)

        this.tabItems.forEach(item => {
            item.setAttribute('tabindex', '0')
        })

        this.updateContentVisibility(this.contentItemsInstanceMap['default'])
        this.tabItems[0].focus()
    }

    handleCloseClicked(e) {
        this.toggleNavMenu(e)

        // Axe Issue 1388117 / 2.4.3.a Focus Order:
        // We have to focus the open button after close was clicked
        this.navMenuToggle.focus()
    }

    closeNavMenu() {
        Emitter.off('keydown', this.handleMenuKeyDown)
        document.body.classList.remove(SELECTORS.NAV_ACTIVE)
        this.searchScrim.classList.remove('open-scrim')

        this.navMenuToggle.setAttribute('aria-expanded', false)
        this.navMenuToggle.setAttribute('aria-label', 'Open Menu')
        this.navMenu.setAttribute('aria-hidden', true)

        // Need to delete first to remove element references.
        delete this.previousNavPanel
        this.previousNavPanel = null

        if (this.activeNavPanel) {
            this.activeNavPanel.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.SHOWING)
            this.activeNavPanel.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }

        this.tabItems.forEach(item => {
            item.setAttribute('tabindex', '-1')
        })

        enableBodyScroll(this.navMenu)

        // Reset the default nav state.
        this.resetNav()
    }

    toggleNavMenu(e) {
        e.preventDefault()
        if (document.body.classList.contains(SELECTORS.NAV_ACTIVE)) {
            this.closeNavMenu()

        } else {
            this.openNavMenu()
            this.closeSearchBar()
        }
    }

    resetNav() {
        this.generateContentItemsMap()
        this.updateLinkVisibility(null)
        TweenMax.to(this.animationItems, 0.5, { opacity: 0 })
        //Hide content items
        this.contentItems.forEach(item => {
            item.classList.remove(GLOBAL_CONSTANTS.CLASSES.SHOWING)
        })

        //Reset scrollbar overflow
        document.querySelector('.navigation__menu-wrapper').style.overflow = "hidden"
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown() {
        Emitter.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll)
        clearAllBodyScrollLocks()
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NavComponent = {
    'name': 'Nav',
    'class': SELECTORS.COMPONENT,
    'Source': Nav
}