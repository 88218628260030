const SELECTORS = {
    COMPONENT: '.artwork-checklist',
    GRID_VIEW: '.artwork-checklist__grid-button',
    LIST_VIEW: '.artwork-checklist__list-button',
    ARTWORK_WRAPPER: '.artwork-checklist__list',
    ARTWORK_ARTIST: '.js-artwork-checklist-artwork-caption',
    ARTWORK_ITEM: '.js-artwork-checklist-artwork-outer-wrapper',
    LOAD_MORE: '.load-artwork__button'
}

export default class ArtworkChecklist {
    /**
     * @desc Toggle between grid view & list view on Artwork Checklist Component.
     */


    constructor(element) {
        this.element = element
        this.gridButton = this.element.querySelector(SELECTORS.GRID_VIEW)
        this.listButton = this.element.querySelector(SELECTORS.LIST_VIEW)
        this.artworkChecklistWrapper = this.element.querySelector(SELECTORS.ARTWORK_WRAPPER)
        this.artworkArtist = this.element.querySelector(SELECTORS.ARTWORK_ARTIST)
        this.artworkItems = Array.from(this.artworkChecklistWrapper.querySelectorAll(".js-artwork-checklist-artwork-outer-wrapper"))
        this.loadMoreBtn = this.element.querySelector(SELECTORS.LOAD_MORE)
        this.initialize()
    }

    initialize() {
        this.activateListView()
        this.activateGridView()
        this.loadMore()
    }

    activateListView() {
        this.listButton.addEventListener('click', (event) => {
            event.preventDefault()
            this.artworkChecklistWrapper.classList.add("list-view")
            this.artworkArtist.classList.add("col-md-4")
            this.listButton.classList.add("-active")
            this.gridButton.classList.remove("-active")
            this.listButton.ariaSelected = true
            this.gridButton.ariaSelected = false
        })
    }

    activateGridView() {
        this.gridButton.addEventListener('click', (event) => {
            event.preventDefault()
            this.artworkChecklistWrapper.classList.remove("list-view")
            this.artworkArtist.classList.remove("col-md-4")
            this.listButton.classList.remove("-active")
            this.gridButton.classList.add("-active")
            this.listButton.ariaSelected = false
            this.gridButton.ariaSelected = true
        })
    }

    loadMore() {
        var maxShow = 8
        var loadItems = 4
        var hiddenClass = "hide-artworkblock"

        this.artworkItems.forEach(function (item, index) {
            if (index > maxShow - 1) {
                item.classList.add(hiddenClass)
            }
        })

        if (this.loadMoreBtn) {
            this.loadMoreBtn.addEventListener("click", function () {
                Array.prototype.forEach.call(document.querySelectorAll("." + hiddenClass), function (
                    item,
                    index
                ) {
                    if (index < loadItems) {
                        item.classList.remove(hiddenClass)
                    }
                    if (document.querySelectorAll("." + hiddenClass).length === 0) {
                        document.querySelector('.load-artwork__wrapper').style.display = "none"
                    }
                })
            })
        }
    }
}

export const ArtworkChecklistComponent = {
    'name': 'ArtworkChecklist',
    'class': SELECTORS.COMPONENT,
    'Source': ArtworkChecklist
}
