const CLASSES = {
    COMPONENT: '.js-content-showcase',
    CONTENT_BUTTONS: '.js-content-showcase-content-button',
    INNER_CONTENTS: '.js-content-showcase-inner-contents'

}

export default class ContentShowcase {
    constructor(element) {
        this.element = element
        this.contentButtons = this.element.querySelectorAll(CLASSES.CONTENT_BUTTONS)
        this.innerContents = this.element.querySelectorAll(CLASSES.INNER_CONTENTS)

        this.init()
    }

    init() {
        this.contentButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.highlightSelectedButton(button)
                this.showContents(button)
            })
        })
    }

    highlightSelectedButton(selectedbutton) {
        this.contentButtons.forEach(contentButton => {
            contentButton.classList.remove('-active')
        })
        selectedbutton.classList.add('-active')
    }

    showContents(selectedButton) {
        this.innerContents.forEach(innerContent => {
            if (innerContent.dataset.index === selectedButton.dataset.index) {
                innerContent.classList.add('-active')
            } else {
                innerContent.classList.remove('-active')
            }
        })
    }
}

export const ContentShowcaseComponent = {
    'name': 'ContentShowcase',
    'class': CLASSES.COMPONENT,
    'Source': ContentShowcase
}