import Emitter from '../utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import smoothscroll from 'smoothscroll-polyfill'
import { TweenMax, Power3 } from 'gsap/all'

const SELECTORS = {
    COMPONENT: '.js-index-nav-list',
    LIST: '.js-index-nav-list-list',
    LIST_ITEM: '.js-index-nav-list-item',
    LIST_LINK: '.js-index-nav-list-link',
    ALPHABET_LIST: '.js-alphabet-list',
    ALPHABET_ITEM: '.js-alphabet-item',
    IMAGES_LIST: '.js-index-nav-images'
}


export default class IndexNavigation {
    /**
     * @desc Set up Index Navigation with elements and bind events.
     * @param {HTMLElement} el - Container element for Index Navigation.
     *
     */

    constructor(element) {
        this.element = element

        this.itemList = this.element.querySelectorAll(SELECTORS.LIST_ITEM)
        this.letterList = this.element.querySelectorAll(SELECTORS.ALPHABET_ITEM)
        this.itemsEl = this.element.querySelector(SELECTORS.LIST)
        this.alphabetList = this.element.querySelector(SELECTORS.ALPHABET_LIST)
        this.imagesList = this.element.querySelector(SELECTORS.IMAGES_LIST)

        this.throttleScroll = null
        this.hasAlphabetList = this.element.dataset.hasAlphabet
        this.itemListInstanceMap = {}
        this.allLetters = {}
        this.currentLetter = null

        smoothscroll.polyfill()
        this.init()
    }

    init() {
        this.generateItemMap()
        this.registerListeners()
        this.updateFixedItems()

        //Set first letter to active on page load.
        this.updateAlphabetStatus()
    }

    showList() {
        const duration = 0.6
        TweenMax.staggerFromTo(
            this.itemList,
            duration,
            { opacity: 0, y: 50, ease: Power3.easeOut },
            { opacity: 1, y: 0, ease: Power3.easeOut },
            0.04
        )
    }

    generateItemMap() {
        // Create itemListInstanceMap object from the <ul>.
        this.itemList.forEach((item, i) => {
            this.itemListInstanceMap[i] = {
                el: item,
                sortName: item.dataset.sortName,
                imageWrapper: this.element.querySelector(`#item-image-${item.dataset.itemId}`),
                image: null
            }

            const itemLink = item.querySelector(SELECTORS.LIST_LINK)
            if (itemLink) {
                itemLink.addEventListener('mouseover', this.onMouseOver.bind(this))
                itemLink.addEventListener('mouseout', this.onMouseOff.bind(this))
            }
        })


        // Loop over the #alphabet ul and match for item strings.
        this.letterList.forEach(item => {
            const char = item.dataset.letter
            let itemEls = []
            item.disabled = true
            item.setAttribute('aria-label', `jump to artists starting with ${char} - disabled`)

            this.allLetters[char] = {
                el: item,
                letter: char,
                itemEls: itemEls,
                isActive: false
            }

            for (let key in this.itemListInstanceMap) {

                if (this.itemListInstanceMap[key].sortName.charAt(0).toLowerCase() === char.toLowerCase()) {
                    itemEls.push(this.itemListInstanceMap[key].el)
                    item.disabled = false
                    item.setAttribute('aria-label', `jump to artists starting with ${char}`)

                    this.allLetters[char] = {
                        el: item,
                        letter: char,
                        itemEls: itemEls,
                        sectionStart: itemEls[0],
                        sectionEnd: itemEls.slice(-1)[0],
                        isActive: true
                    }

                    //Add click handler to button
                    item.addEventListener('click', () => {
                        this.jumpTo(char)
                    })
                }
            }
        })


        this.showList()

    }

    registerListeners() {
        // Add scroll listener.
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)
    }

    handleScroll() {
        this.updateFixedItems()
        if (this.hasAlphabetList) {
            this.updateAlphabetStatus()
        }
    }

    jumpTo(key) {
        const itemEl = this.allLetters[key].itemEls[0]
        const itemElTop = itemEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP
        window.scrollTo({
            top: itemElTop,
            left: 0,
            behavior: 'smooth'
        })
    }

    updateFixedItems() {
        let fromTop = window.scrollY
        const fromBottom = this.element.getBoundingClientRect().height - fromTop - window.innerHeight
        const listTop = this.itemsEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP
        let listIsOffScreen = false

        if (fromTop > listTop) {
            listIsOffScreen = true
        }

        // Unlock the fixed elements when component hits the bottom of the viewport.
        if (fromBottom < 1 && listIsOffScreen) {
            if (this.hasAlphabetList) {
                this.alphabetList.classList.add('locked')
            }
            this.imagesList.classList.add('locked')
        } else {
            if (this.hasAlphabetList) {
                this.alphabetList.classList.remove('locked')
            }
            this.imagesList.classList.remove('locked')
        }
    }

    updateAlphabetStatus() {
        let fromTop = window.scrollY
        const listTop = this.itemsEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP

        // Set the class on the letter of the active section.
        for (let key in this.allLetters) {
            const item = this.allLetters[key]
            const link = item.el

            if (!item.sectionEnd) {
                item.sectionEnd = item.sectionStart
            }

            // If scroll is higher than first item, set first active item as current.
            if (fromTop < listTop) {
                if (this.currentLetter) {
                    this.currentLetter.classList.remove('current')
                }

                if (item.isActive) {
                    link.classList.add('current')
                    return (false)
                }
            } else {
                if (
                    item.sectionStart &&
                    item.sectionStart.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP <= fromTop &&
                    item.sectionEnd.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP + item.sectionEnd.offsetHeight > fromTop
                ) {
                    link.classList.add('current')
                    this.currentLetter = link
                } else {
                    link.classList.remove('current')
                }
            }

        }
    }

    onMouseOver(event) {
        const itemLink = event.target
        const itemLinkList = event.target.parentNode.parentNode
        const itemId = event.target.parentNode.dataset.itemId
        const instanceMapItem = this.itemListInstanceMap[itemId - 1]
        const itemImage = instanceMapItem.imageWrapper

        itemLink.classList.add('active')
        itemLinkList.classList.add('active')

        clearTimeout(this.enterTimeout)
        clearTimeout(this.leaveTimeout)

        // Do when hovering on list item
        itemImage.classList.add('active')
        // If an image already exists, show image.
        if (instanceMapItem.image) {
            this.enterTimeout = setTimeout(() => {
                // Check if hover is resting on link
                if (itemLink.classList.contains('active')) {
                    itemImage.classList.add('loaded')
                }
            }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
        } else {
            // If no image exists, load the image and show it.
            const itemImageSrc = itemImage.dataset.imgSrc
            this.loadImage(itemLink, itemImage, itemImageSrc, instanceMapItem)
        }
    }

    onMouseOff(event) {
        const itemLink = event.target
        const itemLinkList = event.target.parentNode.parentNode
        const itemId = event.target.parentNode.dataset.itemId
        const instanceMapItem = this.itemListInstanceMap[itemId - 1]
        const itemImage = instanceMapItem.imageWrapper

        itemLink.classList.remove('active')
        itemLinkList.classList.remove('active')

        clearTimeout(this.enterTimeout)
        clearTimeout(this.leaveTimeout)

        // Do when hover leaves item
        itemImage.classList.remove('loaded')
        this.leaveTimeout = setTimeout(() => {
            itemImage.classList.remove('active')
        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
    }

    loadImage(itemLink, itemImage, imgSrc, instanceMapItem) {
        const imageHolder = itemImage.querySelector('.image-holder')
        const newImage = new Image()

        newImage.onload = () => {
            setTimeout(() => {
                // Check if hover is resting on link
                if (itemLink.classList.contains('active')) {
                    itemImage.classList.add('loaded')
                }
            }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
        }

        newImage.src = imgSrc
        imageHolder.appendChild(newImage)
        instanceMapItem.image = newImage
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const IndexNavigationComponent = {
    'name': 'IndexNavigation',
    'class': SELECTORS.COMPONENT,
    'Source': IndexNavigation
}