import Emitter from '../utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import smoothscroll from 'smoothscroll-polyfill'

const SELECTORS = {
    COMPONENT: '.js-artist-sections',
    SECTION: '.js-artist-sections-section',
    DYNAMIC_CONTENT_AREA: '.js-nav-dynamic-content'
}


export default class ArtistNav {
    /**
     * @desc Set up Sub Nav with elements and bind events.
     * @param {HTMLElement} el - Container element for Link List
     *
     */

    constructor(element) {
        this.element = element
        this.artistSections = this.element.querySelectorAll(SELECTORS.SECTION)
        this.dynamicContentArea = document.querySelector(SELECTORS.DYNAMIC_CONTENT_AREA)
        this.artistNavList = null
        this.sectionMap = {}
        this.throttleScroll = null
        this.jumpToSection = this.jumpToSection.bind(this)

        smoothscroll.polyfill()
        this.generateMap()
        this.createNav()
        this.addScrollBehavior()
    }

    generateMap() {
        this.artistSections.forEach(item => {
            const sectionTitle = item.dataset.sectionTitle
            const sectionId = item.dataset.sectionId
            const sectionObj = {
                sectionId: sectionId,
                el: item,
                title: sectionTitle
            }

            this.sectionMap[sectionId] = sectionObj
        })
    }

    createNav() {
        const navUl = document.createElement('ul')
        navUl.classList.add('artist-nav')
        navUl.setAttribute('role', 'artist-navigation')

        for (const key in this.sectionMap) {
            const navLi = document.createElement('li')
            const navA = document.createElement('a')
            const linkText = document.createTextNode(this.sectionMap[key].title)
            navA.appendChild(linkText)
            navA.title = this.sectionMap[key].title
            navA.dataset.target = this.sectionMap[key].sectionId
            navA.classList.add('artist-nav__link')
            navA.addEventListener('click', this.jumpToSection)
            navLi.appendChild(navA)
            navUl.appendChild(navLi)
            this.sectionMap[key].link = navA
        }

        this.dynamicContentArea.appendChild(navUl)
        this.artistNavList = navUl
    }

    jumpToSection(event) {
        event.stopPropagation()
        const target = event.target.dataset.target
        const sectionEl = this.sectionMap[`${target}`].el
        const sectionElTop = sectionEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP

        window.scrollTo({
            top: sectionElTop + 1,
            left: 0,
            behavior: 'smooth'
        })
    }

    addScrollBehavior() {
        // Add scroll listener.
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)
    }

    handleScroll() {
        let fromTop = window.scrollY
        let activeCount = 0

        for (const key in this.sectionMap) {
            const item = this.sectionMap[key]
            const sectionEl = item.el
            const sectionLink = item.link

            if (
                sectionEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP - (GLOBAL_CONSTANTS.SIZES.COMPONENT_MARGIN_DESKTOP / 2) <= fromTop &&
                sectionEl.getBoundingClientRect().top + window.scrollY - GLOBAL_CONSTANTS.SIZES.NAV_HEIGHT_DESKTOP + sectionEl.offsetHeight + (GLOBAL_CONSTANTS.SIZES.COMPONENT_MARGIN_DESKTOP / 2) > fromTop
            ) {
                activeCount++
                sectionLink.classList.add('-active')
            } else {
                sectionLink.classList.remove('-active')
            }
        }

        if (activeCount > 0) {
            this.artistNavList.classList.add('-active')
        } else {
            this.artistNavList.classList.remove('-active')
        }
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ArtistNavComponent = {
    'name': 'ArtistNav',
    'class': SELECTORS.COMPONENT,
    'Source': ArtistNav
}