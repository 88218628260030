import Emitter from '../utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from '../utils/constants'

/**
 * Creates show/hide functionality for index page filters
 */

const ATTRIBUTES = {
    FILTER_EXPANDED: 'aria-expanded',
    BODY_HIDDEN: 'aria-hidden'
}

const SELECTORS = {
    COMPONENT: '.js-filter',
    LIST: '.js-list',
    LIST_ITEM: '.js-list-item',
    FILTER_TOGGLE: '.js-filter-toggle',
    FILTER_BODY_WRAPPER: '.js-filter-body-wrapper',
    FILTER_FORM_INPUT: '.js-filter-form-input',
    FILTER_BODY: '.js-filter-body'
}

export default class Filter {
    /**
     * @desc Set up filter with elements and bind events.
     * @param {HTMLElement} el - Container element for filter
     *
     */

    constructor(element) {
        this.element = element
        this.bodyWrapperInstances = {}
        this.linkList = this.element.querySelector(SELECTORS.LIST)
        this.listItems = this.element.querySelectorAll(SELECTORS.LIST_ITEM)
        this.toggleButtons = this.element.querySelectorAll(SELECTORS.FILTER_TOGGLE)
        this.formInputs = this.element.querySelectorAll(SELECTORS.FILTER_FORM_INPUT)
        this.bodyWrappers = this.element.querySelectorAll(SELECTORS.FILTER_BODY_WRAPPER)

        this.onMouseEvent = this.onMouseEvent.bind(this)
        this.onToggleButtonClick = this.onToggleButtonClick.bind(this)

        this.initialize()
    }

    initialize() {
        this.registerEvents()
        this.generateMappings()
    }

    generateMappings() {
        this.bodyWrappers.forEach(item => {
            const instance = {
                el: item,
                innerWrapper: item.querySelector(SELECTORS.FILTER_BODY),
                bodyInputs: item.querySelectorAll(SELECTORS.FILTER_FORM_INPUT)
            }

            this.bodyWrapperInstances[item.dataset.target] = instance
        })
    }

    /**
     * @desc Register resize listener with attached throttle
     */
    registerEvents() {
        this.listItems.forEach(item => {
            item.addEventListener('mouseover', this.onMouseEvent)
            item.addEventListener('mouseout', this.onMouseEvent)
        })

        this.throttleResize = throttle(
            GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE,
            this.updateFilterHeight.bind(this)
        )
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.throttleResize)


        this.toggleButtons.forEach(toggleButton => {
            toggleButton.addEventListener(
                'click',
                this.onToggleButtonClick
            )
        })
    }

    onMouseEvent(event) {
        event.target.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HOVER)
        this.linkList.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HOVER)
    }

    onToggleButtonClick(event) {
        event.preventDefault()

        this.toggleButtons.forEach(toggleButton => {
            const link = toggleButton.dataset.link

            const bodyWrapperInstance = this.bodyWrapperInstances[link]

            const itemBodyHeight = bodyWrapperInstance.innerWrapper.offsetHeight

            if (toggleButton.dataset.link === event.target.dataset.link) {
                const itemIsActive = bodyWrapperInstance.el.classList.contains(
                    GLOBAL_CONSTANTS.CLASSES.ACTIVE
                )

                if (itemIsActive) {
                    this.toggleBodyClose(toggleButton, bodyWrapperInstance)
                } else {
                    this.toggleBodyOpen(
                        toggleButton,
                        bodyWrapperInstance,
                        itemBodyHeight
                    )
                }
            } else {
                this.toggleBodyClose(toggleButton, bodyWrapperInstance)
            }
        })
    }

    toggleBodyClose(toggleButton, bodyWrapperInstance) {
        toggleButton.setAttribute(ATTRIBUTES.FILTER_EXPANDED, false)
        bodyWrapperInstance.el.setAttribute(ATTRIBUTES.BODY_HIDDEN, true)
        bodyWrapperInstance.el.style.height = 0
        toggleButton.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        bodyWrapperInstance.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        bodyWrapperInstance.bodyInputs.forEach(formInput => {
            formInput.setAttribute('tabindex', '-1')
        })
    }

    toggleBodyOpen(toggleButton, bodyWrapperInstance, itemBodyHeight) {
        toggleButton.setAttribute(ATTRIBUTES.FILTER_EXPANDED, true)
        bodyWrapperInstance.el.setAttribute(ATTRIBUTES.BODY_HIDDEN, false)
        bodyWrapperInstance.el.style.height = `${itemBodyHeight}px`
        toggleButton.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        bodyWrapperInstance.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        bodyWrapperInstance.bodyInputs.forEach(formInput => {
            formInput.setAttribute('tabindex', '0')
        })

        // Axe 1388075 2.4.3.a Focus Order
        // Set the focus on the first element of the sub-menu
        if (bodyWrapperInstance.bodyInputs.length) {
            bodyWrapperInstance.bodyInputs[0].focus()
        }
    }

    /**
     * @desc update filter height on resize
     */
    updateFilterHeight() {
        Object.keys(this.bodyWrapperInstances).forEach(key => {
            const instance = this.bodyWrapperInstances[key]

            const itemIsActive = instance.el.classList.contains(
                GLOBAL_CONSTANTS.CLASSES.ACTIVE
            )
            const itemBodyHeight = instance.innerWrapper.offsetHeight

            if (itemIsActive) {
                instance.el.style.height = `${itemBodyHeight}px`
            }
        })
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const FilterComponent = {
    name: 'Filter',
    'class': SELECTORS.COMPONENT,
    Source: Filter
}
