import {
    GLOBAL_CONSTANTS
} from '../utils/constants'
import Emitter from '../utils/emitter'
import axios from 'axios'
import getCookie from '../utils/getCookie'

const ATTRIBUTES = {
    FORM_EXPANDED: 'aria-expanded',
    BODY_HIDDEN: 'aria-hidden'
}

const CLASSES = {
    COMPONENT: '.js-career-form-wrapper',
    CAREER_FORM: '.js-career-form',
    APPLY_BUTTON: '.js-career-apply-button',
    CAREER_FORM_WRAPPER: '.js-form-submit-wrapper',
    FORM_INPUT_WRAPPER: '.js-careers-form-input-wrapper',
    FILE_INPUT_INSTANCE: '.js-career-file-input-instance',
    FILE_BUTTON: '.js-career-file-button',
    FILE_INPUT: '.js-career-file-input',
    FILE_NAME: '.js-career-file-name',
    FILE_ERROR: '.js-file-error',
    UPLOAD_STATUS: '.js-career-upload-status'

}

export default class CareerForm {
    /**
     * @desc Set up Career Listing Form with elements and bind events.
     * @param {HTMLElement} element - Container element for form
     *
     */

    constructor(element) {
        this.element = element
        this.fileInputInstances = {}
        this.form = this.element.querySelector(CLASSES.CAREER_FORM)
        this.applyButton = this.element.querySelector(CLASSES.APPLY_BUTTON)
        this.formWrapper = this.element.querySelector(CLASSES.CAREER_FORM_WRAPPER)
        this.formInputWrapper = this.element.querySelector(CLASSES.FORM_INPUT_WRAPPER)
        this.uploadStatus = this.element.querySelector(CLASSES.UPLOAD_STATUS)

        this.checkFileExtension = this.checkFileExtension.bind(this)
        this.showFileName = this.showFileName.bind(this)
        this.clearFileName = this.clearFileName.bind(this)

        this.initialize()
    }

    initialize() {
        this.generateMappings()
        this.registerListeners()
    }

    generateMappings() {
        this.fileInputs = this.element.querySelectorAll(CLASSES.FILE_INPUT_INSTANCE)
        this.fileInputs.forEach(item => {
            const instance = {
                el: item,
                fileButton: item.querySelector(CLASSES.FILE_BUTTON),
                fileInput: item.querySelector(CLASSES.FILE_INPUT),
                fileName: item.querySelector(CLASSES.FILE_NAME),
                fileError: item.querySelector(CLASSES.FILE_ERROR)
            }


            instance.fileButton.addEventListener('click', (event) => {
                event.preventDefault()
                instance.fileError.innerText = ''
                instance.fileInput.click()
            })
            instance.fileInput.addEventListener('change', this.checkFileExtension)
            instance.fileName.addEventListener('click', (event) => {
                event.preventDefault()
                this.clearFileName(instance)
            })

            this.fileInputInstances[instance.fileInput.dataset.target] = instance
        })
    }

    registerListeners() {
        this.form.addEventListener('submit', this.formSubmit.bind(this))

        this.applyButton.addEventListener('click', (event) => {
            this.openForm(event.target) // eslint-disable-line no-undef
        })

    }


    checkFileExtension(event) {
        const instance = this.fileInputInstances[event.target.dataset.target]
        const supportedExtensions = instance.fileInput.accept.replace(/\./g, '').split(', ')
        const file = event.target.files.item(0)
        const path = file.name.split('.')
        const extension = path[path.length - 1].toLowerCase()

        if (supportedExtensions.includes(extension)) {
            this.showFileName(instance, file)
        } else {
            this.clearFileName(instance)
            instance.fileError.innerText = GLOBAL_CONSTANTS.FORM_MESSAGES.FORMAT_ERROR

        }
    }


    showFileName(instance, file) {
        instance.fileName.innerText = file.name
        instance.fileName.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    clearFileName(instance) {
        instance.fileInput.value = ''
        instance.fileName.innerText = ''
        instance.fileName.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    openForm() {
        this.form.setAttribute(ATTRIBUTES.FORM_EXPANDED, true)
        this.form.setAttribute(ATTRIBUTES.BODY_HIDDEN, false)
        this.form.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.applyButton.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
    }

    formSubmit(event) {
        event.preventDefault()

        this.form.querySelector('button[type="submit"]').setAttribute('disabled', 'disabled')

        const formData = new FormData(event.target)
        formData.set('csrfmiddlewaretoken', getCookie('csrftoken'))

        axios.post(GLOBAL_CONSTANTS.URLS.CAREERS_APP_FORM_POST_URL, formData, {
            onUploadProgress: (progress) => {
                const percentCompleted = Math.round((progress.loaded * 100) / progress.total)
                this.uploadStatus.innerHTML = 'Uploading ... ' + percentCompleted + '%'
            }})
            .then((response) => {
                if (response.status === 200) {
                    const domParser = new DOMParser()
                    const parsedDocument = domParser.parseFromString(response.data, 'text/html')
                    this.element.innerHTML = parsedDocument.body.firstChild.innerHTML
                }
            })
            .catch((error) => {

                this.form.querySelector('button[type="submit"]').removeAttribute('disabled')
                this.uploadStatus.innerHTML = ''

                if (error.response && error.response.status === 400) {
                    const domParser = new DOMParser()
                    const parseDocument = domParser.parseFromString(error.response.data, 'text/html')
                    this.formInputWrapper.innerHTML = parseDocument.querySelector(CLASSES.FORM_INPUT_WRAPPER).innerHTML
                    this.generateMappings()
                } else {
                    this.element.innerHTML = '<div>Unable to submit the form at this time. Please send an email to <a style="color: revert;" href="mailto:jobs@pacegallery.com">jobs@pacegallery.com</a>.</div>'
                    Emitter.emit('exception', {
                        error,
                        req: GLOBAL_CONSTANTS.URLS.CAREERS_APP_FORM_POST_URL
                    })
                }
            })
    }
}

/**
 * @desc Career Listing Form component definition used in module-loader
 */

export const CareerFormComponent = {
    'name': 'CareerForm',
    'class': CLASSES.COMPONENT,
    'Source': CareerForm
}