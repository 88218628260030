import { GLOBAL_CONSTANTS } from '../utils/constants'

/**
 * Makes the nav show / hide based on
 * scroll position / direction.
 */

const SELECTORS = {
    COMPONENT: '.js-nav-index-list',
    LIST_ITEM: '.js-nav-index-list-item',
    LIST_ITEM_LINK: '.js-nav-index-list-link',
    NAV_MENU: '.js-nav-menu'
}

export default class NavIndexList {
    constructor(element) {
        this.el = element
        this.indexListItems = this.el.querySelectorAll(SELECTORS.LIST_ITEM)
        this.indexListLinks = this.el.querySelectorAll(SELECTORS.LIST_ITEM_LINK)
        this.previewEl = document.getElementById(this.el.dataset.previewTarget)
        this.indexItemsInstanceMap = {}
        this.navMenu = document.querySelector(SELECTORS.NAV_MENU)

        this.initialize()
    }

    initialize() {
        this.generateIndexItemsMap()
        this.registerEvents()
    }

    registerEvents() {
        this.indexListLinks.forEach((item) => {
            item.addEventListener('mouseover', this.onMouseEvent.bind(this))
            item.addEventListener('mouseout', this.onMouseEvent.bind(this))
        })
    }

    generateIndexItemsMap() {
        this.indexListItems.forEach((item, i) => {
            this.indexItemsInstanceMap[i] = {
                index: i,
                el: item,
                active: false,
                imageSrc: item.dataset.imgSrc,
                imageIsLoaded: false,
                imageEl: null
            }
            item.dataset.index = i
        })
    }

    onMouseEvent(event) {
        const itemLink = event.currentTarget
        const itemLi = itemLink.parentNode

        for (let key in this.indexItemsInstanceMap) {
            const item = this.indexItemsInstanceMap[key]

            if (item.imageSrc) {
                if (key === itemLi.dataset.index) {
                    item.active = true

                    if (item.imageIsLoaded) {
                        setTimeout(() => {
                            // Check if hover is resting on link
                            if (itemLink.classList.contains('-active')) {
                                item.imageEl.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOWING)
                            }

                        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)

                    } else {
                        this.loadImage(itemLink, this.previewEl, item.imageSrc, item)
                    }
                } else {
                    item.active = false
                    if (item.imageIsLoaded) {
                        item.imageEl.classList.remove(GLOBAL_CONSTANTS.CLASSES.SHOWING)
                    }
                }
            }
        }

        itemLink.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)

        if (itemLink.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            // Hovering on a link.
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            // Stop hover action.
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            const itemKey = itemLi.dataset.index
            const prevItem = this.indexItemsInstanceMap[itemKey]
            if (prevItem.imageEl) {
                prevItem.imageEl.classList.remove(GLOBAL_CONSTANTS.CLASSES.SHOWING)
            }
        }
    }

    loadImage(itemLink, imageHolder, imgSrc, instanceMapItem) {
        const newImage = new Image()
        newImage.onload = () => {
            setTimeout(() => {
                // Check if hover is resting on link
                if (itemLink.classList.contains('-active')) {
                    newImage.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOWING)
                }

            }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
        }
        newImage.classList.add('index__preview-img')
        newImage.id = `index-img-${instanceMapItem.index}`
        newImage.src = imgSrc
        imageHolder.appendChild(newImage)
        instanceMapItem.imageIsLoaded = true
        instanceMapItem.imageEl = newImage
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NavIndexListComponent = {
    'name': 'NavIndexList',
    'class': SELECTORS.COMPONENT,
    'Source': NavIndexList
}
