import { renderer } from '../utils/Zoom'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

const CLASSES = {
    COMPONENT: '.js-zoom-wrapper',
    ZOOM_ACTIVATE_BUTTON: '.js-zoom-activate',
    ZOOM_DEACTIVATE_BUTTON: '.js-zoom-deactivate',
    ZOOM_CONTAINER: '.js-zoom-container',
    ZOOM_IMAGE_CONTAINER: '.js-zoom-image-container',
    ZOOM_IMAGE: '.js-zoom-image',
    INCREASE_ZOOM: '.js-increase-zoom',
    DECREASE_ZOOM: '.js-decrease-zoom',
    MOBILE_ZOOM_CONTAINER: '.js-mobile-artwork-zoom'
}

export default class ArtworkZoom {
    minImageWidth = 200
    minImageHeight = 200
    zoomAmount = 10
    minScale = 0.8
    maxScale = 15
    scaleSensitivity = 50

    constructor(element) {
        this.element = element
        this.zoomActivate = this.element.querySelector(CLASSES.ZOOM_ACTIVATE_BUTTON)
        this.zoomDeactivate = this.element.querySelector(CLASSES.ZOOM_DEACTIVATE_BUTTON)
        this.zoomContainer = this.element.querySelector(CLASSES.ZOOM_CONTAINER)
        this.zoomImageContainer = this.element.querySelector(CLASSES.ZOOM_IMAGE_CONTAINER)
        this.zoomImage = this.element.querySelector(CLASSES.ZOOM_IMAGE)
        this.increaseZoomButton = this.element.querySelector(CLASSES.INCREASE_ZOOM)
        this.decreaseZoomButton = this.element.querySelector(CLASSES.DECREASE_ZOOM)
        this.mobileZoomContainer = this.element.querySelector(CLASSES.MOBILE_ZOOM_CONTAINER)
        this.tabOrder = [this.zoomDeactivate, this.increaseZoomButton, this.decreaseZoomButton]
        this.isActivated = false
        this.currentTabIndex = 0
        this.activeModal

        window.addEventListener('carousel-init', event => {
            if (event.detail.carouselId === this.element.dataset.carouselId) {
                this.checkImageSize()
            }
        })
    }

    checkImageSize() {
        // check if image resolution is high enough to activate zoom
        if (this.zoomImage.naturalHeight > this.minImageHeight && this.zoomImage.naturalWidth > this.minImageWidth) {
            this.enableZoomButton()
        }
    }

    enableZoomButton() {
        this.zoomActivate.classList.add('-active')

        this.zoomActivate.addEventListener('click', () => {
            this.activateZoom()
        })
    }

    activateZoom() {
        if (window.getComputedStyle(this.mobileZoomContainer).display === "block") {
            const activeModalContainer = window.document.querySelector('.micromodal-slide.is-open')
            this.activeModal = activeModalContainer.querySelector('.modal__container')
            clearAllBodyScrollLocks()
        }

        document.body.appendChild(this.zoomContainer)
        this.zoomContainer.classList.add('-active')
        this.tabOrder[0].focus()

        const instance = renderer({
            scaleSensitivity: this.scaleSensitivity,
            minScale: this.minScale,
            maxScale: this.maxScale,
            element: this.zoomImageContainer.children[0]
        })

        if (!this.isActivated) {
            this.isActivated = true
            this.registerListeners(instance)
        }
    }

    deactivateZoom() {
        if (window.getComputedStyle(this.mobileZoomContainer).display === "block") {
            disableBodyScroll(this.activeModal)
        }
        this.zoomContainer.classList.remove('-active')
        this.zoomImageContainer.classList.remove('-wide')
        this.zoomImageContainer.classList.remove('-tall')
    }

    handleTabbing() {
        this.zoomContainer.addEventListener('keydown', event => {

            if (event.code.toLowerCase() === 'tab' ) {
                event.stopPropagation()
                event.preventDefault()
                if (event.shiftKey) {
                    this.currentTabIndex = this.currentTabIndex - 1
                    if (this.currentTabIndex < 0) {
                        this.currentTabIndex = this.tabOrder.length - 1
                    }
                } else {
                    this.currentTabIndex = (this.currentTabIndex + 1) % this.tabOrder.length

                }
                this.tabOrder[this.currentTabIndex].focus()
            }
        })
    }

    registerListeners(instance) {
        let isMousedown = false
        let mouseStartX
        let mouseStartY

        this.handleTabbing()

        this.zoomImageContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            instance.zoom({
                deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
                x: event.clientX,
                y: event.clientY
            })
        })

        this.increaseZoomButton.addEventListener('click', () => {
            instance.zoom({
                deltaScale: this.zoomAmount,
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            })
        })

        this.decreaseZoomButton.addEventListener('click', () => {
            instance.zoom({
                deltaScale: this.zoomAmount * -1,
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            })
        })

        this.zoomImageContainer.addEventListener('mousedown', (event) => {
            mouseStartX = event.pageX
            mouseStartY = event.pageY
            isMousedown = true
        })

        this.zoomImageContainer.addEventListener('mouseup', (event) => {
            const diffX = Math.abs(event.pageX - mouseStartX)
            const diffY = Math.abs(event.pageY - mouseStartY)
            const delta = 6
            isMousedown = false

            // detect if user is trying to click rather than click+drag
            // prevents zoom from happening when user clicks+drags to pan
            if (diffX < delta && diffY < delta) {
                instance.zoom({
                    deltaScale: this.zoomAmount,
                    x: event.clientX,
                    y: event.clientY
                })
            }
        })

        this.zoomImageContainer.addEventListener('mousemove', (event) => {
            if (isMousedown) {
                event.preventDefault()
                instance.panBy({
                    originX: event.movementX,
                    originY: event.movementY
                })
            }
        })

        this.zoomDeactivate.addEventListener('click', () => {
            this.deactivateZoom()

            //reset the image position
            instance.panTo({
                originX: 0,
                originY: 0,
                scale: 1
            })
        })
    }

}

export const ArtworkZoomComponent = {
    'name': 'ArtworkZoom',
    'class': CLASSES.COMPONENT,
    'Source': ArtworkZoom
}
