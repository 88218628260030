const SELECTORS = {
    COMPONENT: '.js-exhibition-sections',
    SECTION: '.js-exhibition-sections-section',
    NAV_LINK: '.js-exhibition-nav-link'
}


export default class ExhibitionNav {
    /**
     * @desc Set up Sub Nav with elements and bind events.
     * @param {HTMLElement} el - Container element for Link List
     *
     */

    constructor(element) {
        this.element = element
        this.exhibitionSections = this.element.querySelectorAll(SELECTORS.SECTION)
        this.exhibitionNavLinks = document.querySelectorAll(SELECTORS.NAV_LINK)
        this.scroll = 0
        this.lastScroll = 0
        this.scrollDirection = null

        this.initScrollListener()
        this.initObserver()
    }

    initObserver() {
        let observer

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: 1
        }

        observer = new IntersectionObserver(this.handleIntersect.bind(this), options)

        this.exhibitionSections.forEach(section => {
            observer.observe(section)
        })
    }

    handleIntersect(entries) {
        entries.forEach(entry => {
            if (this.scrollDirection === 'down') {
                this.handleDownScrollingBehavior(entry)
            }

            if (this.scrollDirection === 'up') {
                this.handleUpScrollingBehavior(entry)
            }
        })
    }

    handleDownScrollingBehavior(entry) {
        if (entry.isIntersecting) {
            this.exhibitionNavLinks.forEach(navLink => {
                if (entry.target.id === navLink.dataset.sectionRef) {
                    this.updateActiveSubnavLink(navLink)
                }
            })
        }
    }

    handleUpScrollingBehavior(entry) {
        if (!entry.isIntersecting) {
            const index = Array.from(this.exhibitionNavLinks).findIndex(navLink => {
                return navLink.dataset.sectionRef === entry.target.id
            })

            if (index > 0) {
                const activeNavLink = this.exhibitionNavLinks[index - 1]
                this.updateActiveSubnavLink(activeNavLink)
            }
        }
    }

    updateActiveSubnavLink(activeNavLink) {
        this.exhibitionNavLinks.forEach(item => {
            item.classList.remove('-active')
        })
        activeNavLink.classList.add('-active')
    }

    initScrollListener() {
        window.addEventListener('scroll', () => {
            this.lastScroll = this.scroll
            this.scroll = window.document.scrollingElement.scrollTop
            if (this.scroll > this.lastScroll) {
                this.scrollDirection = 'down'
            } else {
                this.scrollDirection = 'up'
            }
        })
        this.scroll = window.document.scrollingElement.scrollTop
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ExhibitionNavComponent = {
    'name': 'ExhibitionNav',
    'class': SELECTORS.COMPONENT,
    'Source': ExhibitionNav
}