import { ArtworkModalComponent } from '../components/ArtworkModal'
import { ArtworkZoomComponent } from '../components/ArtworkZoom'
import { ArtworkChecklistComponent } from '../components/ArtworkChecklist'
import { CarouselComponent } from '../components/Carousel'
import { CollapsibleBlockComponent } from "../components/CollapsibleBlock"
import { ContentShowcaseComponent } from '../components/ContentShowcase'
import { FilterComponent } from '../components/Filter'
import { LinkListComponent } from '../components/LinkList'
import { IndexNavigationComponent } from '../components/IndexNavigation'
import { ArtistNavComponent } from '../components/ArtistNav'
import { ExhibitionNavComponent } from '../components/ExhibitionNav'
import { NotificationBarComponent } from '../components/NotificationBar'
import { ScrollTargetComponent } from './ScrollTarget'
import { LoadMoreButtonComponent } from '../components/LoadMoreButton'
import { CareerFormComponent } from '../components/CareerForm'
import { FooterComponent } from '../components/Footer'
import { YouTubeVideoComponent } from '../components/YouTubeVideo'
import { BannerComponent } from '../components/Banner'
import { ReadMoreButtonComponent } from '../components/ReadMoreButton'
import { MastheadBlockComponent } from '../components/MastheadBlock'
import { LanguageChooserComponent } from '../components/LanguageChooser'
import { HubspotFormComponent } from "../components/HubspotForm"
import { VideoControlsComponent } from "../components/VideoControls"

/**
 * Add module objects alphabetically as needed
 * {
 *  name: Class Name
 *  class: html .class
 *  Source: imported module
 *  render: Function (if React SSR component)
 * }
 */

export const modules = [
    ArtworkModalComponent,
    ArtworkZoomComponent,
    ArtworkChecklistComponent,
    CarouselComponent,
    CollapsibleBlockComponent,
    ContentShowcaseComponent,
    FilterComponent,
    LinkListComponent,
    IndexNavigationComponent,
    ScrollTargetComponent,
    NotificationBarComponent,
    LoadMoreButtonComponent,
    ArtistNavComponent,
    ExhibitionNavComponent,
    NotificationBarComponent,
    CareerFormComponent,
    FooterComponent,
    YouTubeVideoComponent,
    BannerComponent,
    ReadMoreButtonComponent,
    MastheadBlockComponent,
    LanguageChooserComponent,
    HubspotFormComponent,
    VideoControlsComponent
]

export default modules
