import { GLOBAL_CONSTANTS } from './constants'

const SELECTORS = {
    COMPONENT: '.js-scroll-target'
}

export default class ScrollTarget {
    /**
     * @desc Set up listener to activate components as they scroll into viewport.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */
    constructor(element) {
        this.element = element
        this.observer = this.registerObserver()
        this.observer.observe(this.element)
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     * @param {HTMLElement} el - Image element
     */
    registerObserver() {
        const options = {
            root: null,
            rootMargin: '0px 0px -25% 0px', // trigger element when it's 1/4 of the way into the viewport
            threshold: 0.0
        }

        return new IntersectionObserver(this.elementInView.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the image is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    elementInView(elements) {
        elements.forEach(elem => {
            if (elem.intersectionRatio > 0) {
                const targetEl = elem.target
                this.activateElement(targetEl)

                // If the element contains a video, start playing it on enter.
                if (targetEl.classList.contains('js-has-video')) {
                    const mediaVideo = targetEl.querySelector('video')
                    if (mediaVideo.paused) {
                        mediaVideo.play()
                    }
                }
            }
        })
    }

    /**
     * @desc If the image is in the viewport, set it's source appropriately
     * @param {HTMLElement} img - The image that is in the viewport
     */
    activateElement(elem) {
        elem.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ScrollTargetComponent = {
    'name': 'ScrollTarget',
    'class': SELECTORS.COMPONENT,
    'Source': ScrollTarget
}
