import { GLOBAL_CONSTANTS } from '../utils/constants'

const SELECTORS = {
    COMPONENT: '.js-language-chooser',
    TOGGLE_EL: '.js-language-toggle',
    LANG_LIST: '.js-language-chooser-list',
    LANG_SCRIM: '.js-lang-scrim'
}

export default class LanguageChooser {
    /**
     * @desc Set up Language chooser
     */

    constructor(element) {
        this.element = element
        this.wrapperEl = document.querySelector(SELECTORS.COMPONENT)
        this.toggleEl = document.querySelector(SELECTORS.TOGGLE_EL)
        this.listEl = document.querySelector(SELECTORS.LANG_LIST)
        this.langScrim = document.querySelector(SELECTORS.LANG_SCRIM)
        this.initialize()
    }

    initialize() {
        this.registerEvents()
    }

    isOpen() {
        if (this.wrapperEl.classList.contains('-is-open')) {
            return (true)
        } else {
            return (false)
        }
    }

    navigationObserver() {
        this.closeList()
    }

    registerEvents() {
        this.toggleEl.addEventListener('click', () => {
            if (this.isOpen()) {
                this.closeList()
            } else {
                this.openList()
            }
        })

        this.langScrim.addEventListener('click', () => {
            this.closeList()
        })

        this.observer = new MutationObserver(this.navigationObserver.bind(this))
        this.observer.observe(
            document.querySelector('.js-search-scrim'),
            { attributes: true }
        )
    }

    openList() {
        const targetHeight = this.listEl.offsetHeight
        this.wrapperEl.style.height = `${targetHeight}px`
        this.wrapperEl.classList.add('-is-open')
        this.wrapperEl.setAttribute('aria-hidden', 'false')
        document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.LANG_ACTIVE)
        this.langScrim.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    closeList() {
        this.langScrim.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.wrapperEl.style.height = '0px'
        this.wrapperEl.classList.remove('-is-open')
        this.wrapperEl.setAttribute('aria-hidden', 'true')

        this.searchTimeout = setTimeout(() => {
            document.body.classList.remove(GLOBAL_CONSTANTS.CLASSES.LANG_ACTIVE)
        }, GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)
    }
}

export const LanguageChooserComponent = {
    'name': 'LanguageChooser',
    'class': SELECTORS.COMPONENT,
    'Source': LanguageChooser
}