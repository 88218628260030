export function isMobile() {
    return (window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.MOBILE_LG)
}

export function isStandardTablet() {
    return (window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET)
}

export function isTablet() {
    return (window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET_LG)
}

export function isDesktop() {
    return (window.innerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP)
}

const TIMING = {
    FAST_ANIM_TIME: 250, //this should be kept in parity with the CSS $anim_duration_fast variable
    STD_ANIM_TIME: 600, //this should be kept in parity with the CSS $anim_duration variable
    NAV_SCROLL_THROTTLE: 10,
    STANDARD_THROTTLE: 150,
    RESIZE_THROTTLE: 100,
    MODAL_ANIMATION_DURATION: 300,
    NOTIFICATION_BAR_WAIT: 400
}

const URLS = {
    YOUTUBE_API_URL: 'https://www.youtube.com/iframe_api',
    ARTWORK_FORM_POST_URL: '/api/artwork_inquiry/',
    CAREERS_APP_FORM_POST_URL: '/api/careers_application/',
    VIEWING_ROOM_CAPTURE_URL: '/api/viewing_room_capture/',
    FOOTER_FORM_POST_URL: '/api/footer_form_post/',
    HUBSPOT_FORM_POST_URL: '/api/hubspot_form_post/'
}

const FORM = {
    WRAPPER: '.form__input-wrapper',
    INPUT: '.form__input',
    LABEL: '.form__label'
}

const FORM_MESSAGES = {
    FORMAT_ERROR: 'Unsupported file format.'
}

export const GLOBAL_CONSTANTS = {
    EVENTS: {
        SCROLL: 'scroll',
        RESIZE: 'resize',
        ORIENTATION_CHANGE: 'orientation-change',
        FOCUS: 'focus',
        BLUR: 'blur',
        CHANGE: 'change',
        KEYDOWN: 'keydown',
        FORM_SUBMIT: 'form-submit',
        CLOSE_MOBILE_MENU: 'close-mobile-menu'
    },
    CLASSES: {
        ACTIVE: '-active',
        LOADED: '-loaded',
        FIXED: '-fixed',
        HOVER: '-hover',
        INACTIVE: '-inactive',
        HIDDEN: '-hidden',
        VISIBLE: '-visible',
        TRANSPARENT: '-transparent',
        CURRENT: '-current',
        HAS_CURRENT: '-has-current',
        SHOWING: '-showing',
        NOT_SHOWING: '-not-showing',
        SEARCH_ACTIVE: '-search-active',
        LANG_ACTIVE: '-lang-chooser-active'
    },
    BREAKPOINTS: {
        MOBILE: 320,
        MOBILE_LG: 375,
        TABLET: 768,
        TABLET_LG: 1024,
        DESKTOP: 1280,
        DESKTOP_LG: 1440
    },
    KEYS: {
        ESC: 27
    },
    SIZES: {
        NAV_HEIGHT_DESKTOP: 64,
        NAV_HEIGHT_MOBILE: 53,
        ARTIST_NAV_THRESHOLD: 200,
        COMPONENT_MARGIN_DESKTOP: 160
    },
    TIMING,
    URLS,
    FORM,
    FORM_MESSAGES
}