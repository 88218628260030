import Siema from 'siema'

const CLASSES = {
    COMPONENT: '.js-carousel-wrapper',
    CAROUSEL: '.js-carousel',
    PREVIOUS_BUTTON: '.js-carousel-prev-button',
    NEXT_BUTTON: '.js-carousel-next-button',
    PROGRESS_INDICATOR: '.js-carousel-progress-indicator',
    PROGRESS_LINK: '.js-carousel-progress-link'
}

export default class Carousel {

    constructor(element) {
        this.element = element

        this.carousel = this.element.querySelector(CLASSES.CAROUSEL)
        this.progressBarEl = this.element.querySelector(CLASSES.PROGRESS_INDICATOR)
        this.progressBarLinks = this.element.querySelectorAll(CLASSES.PROGRESS_LINK)
        this.prevArrow = this.element.querySelector(CLASSES.PREVIOUS_BUTTON)
        this.nextArrow = this.element.querySelector(CLASSES.NEXT_BUTTON)

        this.slideCount = parseInt(this.carousel.dataset.count)
        this.barWidth = 100 / this.slideCount

        this.updateSlideIndex = this.updateSlideIndex.bind(this)
        this.onProgressClick = this.onProgressClick.bind(this)

        this.init()
    }

    init() {
        this.registerCarousel()
        this.registerListeners()
    }

    registerCarousel() {
        const that = this

        this.instance = new Siema({
            selector: this.carousel,
            duration: 400,
            easing: 'ease-out',
            perPage: 1,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: false,
            rtl: false,
            startIndex: 0,
            onInit: function() {
                const event = new CustomEvent('carousel-init', {
                    detail: {
                        carouselId: that.carousel.dataset.carouselId
                    }
                })
                window.dispatchEvent(event)
                that.updateSlideIndex(this.currentSlide)
            },
            onChange: function() { that.updateSlideIndex(this.currentSlide) }
        })
    }

    registerListeners() {
        // Event handlers on buttons
        if (this.prevArrow && this.nextArrow) {
            this.prevArrow.addEventListener('click', () => this.instance.prev())
            this.nextArrow.addEventListener('click', () => this.instance.next())
        }

        // Event handlers on Progress Links
        if (this.progressBarEl) {
            this.progressBarLinks.forEach(link => {
                link.addEventListener('click', this.onProgressClick)
            })
        }
    }

    updateSlideIndex(currentSlide) {

        const carouselWrapper = this.element
        const adjustSlidePostion = currentSlide + 1

        //Toggle class for on the first slide
        if (adjustSlidePostion === 1) {
            carouselWrapper.classList.add('--on-first')
        } else {
            carouselWrapper.classList.remove('--on-first')
        }

        //Toggle class for on the last slide
        if (adjustSlidePostion === this.slideCount) {
            carouselWrapper.classList.add('--on-last')
        } else {
            carouselWrapper.classList.remove('--on-last')
        }

        if (this.progressBarEl) {
            this.updateProgressBar(currentSlide)
        }

    }

    onProgressClick(event) {
        event.preventDefault()
        const index = event.target.dataset.count
        this.instance.goTo(index)
    }

    updateProgressBar(currentSlide) {
        // Update the progress bar position.
        this.progressBarEl.style.left = this.barWidth * currentSlide + '%'
        // Set the width of the progress bar.
        this.progressBarEl.style.width = this.barWidth + '%'
    }
}

export const CarouselComponent = {
    'name': 'Carousel',
    'class': CLASSES.COMPONENT,
    'Source': Carousel
}
