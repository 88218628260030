const SELECTORS = {
    COMPONENT: 'video.js-video-controls'
}

export default class VideoControls {
    constructor(element) {
        this.element = element

        const figure = element.closest("figure")
        if (!figure) {
            return
        }

        figure.style.position = "relative"

        figure.addEventListener("mouseover", this.handleMouseOver.bind(this))
        figure.addEventListener("mouseout", this.handleMouseOut.bind(this))

        {
            const html = `<div class="play" style="display: none; position: absolute; left: 0; top: 0; color: white; width: 100%; height: 100%; background-color: rgba(0,0,0,0.45);">
            <button aria-label="Play" style="background: none; border: none; width: 100%; height: 100%;">
                <img src="/static/svg/play.svg" alt="Play" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 50px; height: 50px; filter: invert(100%);" />
            </button>
            </div>`

            figure.insertAdjacentHTML("beforeend", html)
            const playButton = figure.querySelector('button[aria-label="Play"]')
            playButton.addEventListener("click", this.toggle.bind(this))
        }
        {
            const html = `<div class="pause" style="display: none; position: absolute; left: 0; top: 0; color: white; width: 100%; height: 100%; background-color: rgba(0,0,0,0.45);">
            <button aria-label="Pause" style="background: none; border: none; width: 100%; height: 100%;">
                <img src="/static/svg/pause.svg" alt="Pause" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 50px; height: 50px; filter: invert(100%);" />
            </button>
            </div>`

            figure.insertAdjacentHTML("beforeend", html)
            const pauseButton = figure.querySelector('button[aria-label="Pause"]')
            pauseButton.addEventListener("click", this.toggle.bind(this))
        }
    }

    handleMouseOver() {
        this.displayOverlay()
    }

    handleMouseOut() {
        const figure = this.element.closest("figure")
        figure.querySelectorAll('.play, .pause').forEach(el => el.style.display = 'none')
    }

    toggle() {
        if (this.element.paused) {
            this.element.play()
        } else {
            this.element.pause()
        }
        this.displayOverlay()
    }

    displayOverlay() {
        const figure = this.element.closest("figure")
        figure.querySelectorAll('.play, .pause').forEach(el => el.style.display = 'none')
        if (this.element.paused) {
            figure.querySelector('.play').style.display = 'block'
        } else {
            figure.querySelector('.pause').style.display = 'block'
        }
    }
}

export const VideoControlsComponent = {
    'name': 'VideoControls',
    'class': SELECTORS.COMPONENT,
    'Source': VideoControls
}
