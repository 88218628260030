import Emitter from '../utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import Hammer from 'hammerjs'

const SELECTORS = {
    ACTIVE: '.masthead__slide[data-state="active"]',
    COMPONENT: '.js-masthead-block',
    CAROUSEL_SLIDE: 'masthead__slide',
    NEXT_BTN: '.masthead__next',
    PREV_BTN: '.masthead__prev',
    PLAY_PAUSE_BTN: 'play-pause',
    PLAY_SVG: '.play-svg',
    PAUSE_SVG: '.pause-svg',
    PROGRESS_WRAPPER: 'masthead__progress-wrapper',
    PROGRESS_BARS: 'masthead__progress-bar',
    COUNTER: '.masthead__counter',
    IMAGE_WRAPPER: 'masthead__image-wrapper',
    TEXT_WRAPPER: '.masthead__text-wrapper'
}

export default class MastheadBlock {
    /**
     * @desc Set up Masthead block carousel on homepage
     */

    constructor(element) {
        this.element = element
        this.slideItems = document.getElementsByClassName(SELECTORS.CAROUSEL_SLIDE)
        this.totalItems = this.slideItems.length
        this.nextBtn = document.querySelector(SELECTORS.NEXT_BTN)
        this.prevBtn = document.querySelector(SELECTORS.PREV_BTN)
        this.slide = 0
        this.autoplay = null
        this.itemClassName = SELECTORS.CAROUSEL_SLIDE
        this.playPauseBtn = document.getElementById(SELECTORS.PLAY_PAUSE_BTN)
        this.playSVG = document.querySelector(SELECTORS.PLAY_SVG)
        this.pauseSVG = document.querySelector(SELECTORS.PAUSE_SVG)
        this.playing = true
        this.progressBars = document.getElementsByClassName(SELECTORS.PROGRESS_BARS)
        this.progressBarWidth = 1
        this.throttleScroll = null
        this.imageWrapper = document.getElementsByClassName(SELECTORS.IMAGE_WRAPPER)
        this.textWrapper = document.querySelector(SELECTORS.TEXT_WRAPPER)
        this.hammerMasthead = document.querySelector('.masthead')
        this.initialize()

    }

    initialize() {
        this.setInitialClasses()
        this.setEventListeners()
        this.playCarousel()
        this.updateControlColor()
        this.registerEvents()
        this.setViewportWidth()
        this.logWidth()
    }

    setViewportWidth() {
        this.viewportWidth = window.innerWidth
    }

    //conditional for masthead to break to mobile or desktop
    logWidth() {
        if (this.viewportWidth < 1024) {
            this.setMobile()

        } else {
            this.resetDesktop()
        }
    }

    //changes any layout to mobile layout
    setMobile() {
        this.slideItems.forEach((wrapper) => {
            if (wrapper.classList.contains('masthead__inset')) {
                wrapper.style.background = 'linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(' + wrapper.getAttribute('data-imgsrc') + ') no-repeat center center'
                wrapper.style.backgroundSize = 'cover'
                wrapper.style.height = '100vh'
                wrapper.style.top = '-80px'
                wrapper.dataset.controls = 'light-controls'
            }
        })
    }

    // reset to desktop styles
    resetDesktop() {
        this.slideItems.forEach((wrapper) => {
            if (wrapper.classList.contains('masthead__inset')) {
                wrapper.style.height = 'calc(100vh - 236px)'
                wrapper.style.top = '0'
                wrapper.dataset.controls = 'dark-controls'
                if ((wrapper.classList.contains('inset-left')) || (wrapper.classList.contains('inset-right'))) {
                    wrapper.style.background = ''
                    wrapper.style.backgroundSize = ''
                }
            }
        })
    }

    setInitialClasses() {
        // Set active state on first slide
        this.slideItems[0].dataset.state = 'active'

        // Set all other slides as `display:none` to prevent them from being focusable
        Array.from(this.slideItems).slice(1).forEach((wrapper) => wrapper.style.visibility = 'hidden')
    }

    registerEvents() {
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)

        // swipe events
        var Swipe = new Hammer(this.hammerMasthead)

        Swipe.on('swipeleft', () => {
            this.moveNext()
        })

        Swipe.on('swiperight', () => {
            this.movePrev()
        })
    }

    // Set click events to navigation buttons
    setEventListeners() {
        this.nextBtn.addEventListener('click', () => {
            this.moveNext()
        })

        this.prevBtn.addEventListener('click', () => {
            this.movePrev()
        })

        this.playPauseBtn.addEventListener('click', () => {
            if (this.playing) {
                this.pauseCarousel()
            }
            else {
                this.playCarousel()
            }
        })

        //pause if tab is inactive
        document.addEventListener("visibilitychange", () => {
            this.pauseCarousel()
        })

        //on mobile, switch to mobile layout & reset on desktop
        window.addEventListener('resize', () => {
            this.setViewportWidth()
            this.logWidth()
            this.updateControlColor()
        }, false)

    }

    handleScroll() {
        // pause carousel on scroll
        if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
            this.pauseCarousel()
        }
    }

    // Set incoming slide to next and then active once animation has completed
    updateSlides() {
        // Update the active slide
        document.querySelector(SELECTORS.ACTIVE).dataset.state = ''
        this.slideItems[this.slide].dataset.state = 'active'
        this.updateBars()
        this.updateControlColor()

        // Update the slide counter
        this.element.querySelector(SELECTORS.COUNTER).innerText = `${this.slide + 1} / ${this.slideItems.length}`

        // Axe Issue 1387820 / 2.4.3.a Focus Order:
        // Set inactive slides to visibility:hidden to prevent them from getting focus
        // Note that we're not using display:none because of the animation
        // Also add short delay (matching the transition in the SCSS) to retain the fade-out animation
        this.slideItems.forEach((wrapper) => {
            if (wrapper.dataset.state === 'active') {
                wrapper.style.removeProperty("visibility")
            }
        })
        setTimeout(() => {
            this.slideItems.forEach((wrapper) => {
                if (wrapper.dataset.state === 'active') {
                    wrapper.style.removeProperty("visibility")
                } else {
                    wrapper.style.visibility = 'hidden'
                }
            })
        }, GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)
    }

    updateControlColor() {
        if (this.slideItems[this.slide].getAttribute('data-controls') === 'dark-controls') {
            document.querySelector('.masthead__controls').classList.add('dark-controls')
        } else {
            document.querySelector('.masthead__controls').classList.remove('dark-controls')
        }
    }

    // Next navigation handler
    moveNext() {
        // If it's the last slide, reset to 0, else +1
        if (this.slide === (this.totalItems - 1)) {
            this.slide = 0
        } else {
            this.slide++
        }
        this.updateSlides()
    }

    // Previous navigation handler
    movePrev() {
        // If it's the first slide, set as the last slide, else -1
        if (this.slide === 0) {
            this.slide = (this.totalItems - 1)
        } else {
            this.slide--
        }

        // Move carousel to updated slide
        this.updateSlides()
    }

    //pause functionality
    pauseCarousel() {
        this.playSVG.style.display = 'block'
        this.pauseSVG.style.display = 'none'
        this.playPauseBtn.setAttribute("aria-label", "Play")
        this.playing = false
        clearInterval(this.ProgressionInterval)
    }

    //play functionality
    playCarousel() {
        this.playSVG.style.display = 'none'
        this.pauseSVG.style.display = 'block'
        this.playPauseBtn.setAttribute("aria-label", "Pause")
        this.playing = true
        this.ProgressionInterval = setInterval(() => {
            this.progression()
        }, 60)
    }

    updateBars() {
        // Reset timer
        clearInterval(this.ProgressionInterval)
        this.progressBarWidth = 0
        if (this.playing) {
            this.ProgressionInterval = setInterval(() => {
                this.progression()
            }, 60)
        } else {
            // Update the current bar to 0% since progression won't be run
            this.progressBars[this.slide].querySelector('.progress-bar-loader').style.width = '0%'
        }

        // Update non-active bars
        this.progressBars.forEach((bar, index) => {
            if (index > this.slide) {
                bar.querySelector('.progress-bar-loader').style.width = 0
            } else if (index < this.slide) {
                bar.querySelector('.progress-bar-loader').style.width = '100%'
            }
        })
    }

    //transitions width to 100% as a progression animation
    progression() {
        if (this.progressBarWidth >= 100) {
            this.progressBarWidth = 1
            this.moveNext()
        } else {
            this.progressBarWidth++
            this.progressBars[this.slide].querySelector('.progress-bar-loader').style.width = this.progressBarWidth + '%'
        }
    }
}

export const MastheadBlockComponent = {
    'name': 'MastheadBlock',
    'class': SELECTORS.COMPONENT,
    'Source': MastheadBlock
}