import getCookie from "../utils/getCookie"
import {GLOBAL_CONSTANTS} from "../utils/constants"
import Emitter from "../utils/emitter"
import axios from 'axios'
import MicroModal from '../utils/MicroModal'

const LOCALSTORAGE_HUBSPOT_IDS = "hubspotForms"

const CLASSES = {
    COMPONENT: '.js-hubspot-form',
    SUBMIT_BUTTON: '.js-form-submit',
    SUCCESS: '.js-success',
    EMAIL_ERROR: '.js-email-error'
}

export default class HubspotForm {

    constructor(el) {
        this.el = el

        // If this form was previously submitted alread (check localstorage), we do not display the modal
        // and can early return here.
        if (this.wasSubmitted()) {
            return
        }

        this.registerListeners()

        this.modal = new MicroModal({
            awaitCloseAnimation: true,
            targetModal: el.id,
            triggers: document.querySelectorAll("[data-micromodal-trigger=" + el.id)
        })

        // Show the dialog after 5 seconds
        setTimeout(() => {
            this.modal.showModal()
        }, 5000)

        // Workaround close button
        this.el.querySelector("button[data-micromodal-close]").addEventListener('click', () => {
            this.modal.closeModal()
        })
    }

    wasSubmitted() {
        try {
            const displayedFormsString = window.localStorage.getItem(LOCALSTORAGE_HUBSPOT_IDS)
            const displayedForms = JSON.parse(displayedFormsString)
            return displayedForms.indexOf(this.el.id) > -1
            // eslint-disable-next-line no-empty
        } catch (e) {}
        return false
    }

    setSubmitted() {
        let displayedForms
        try {
            const displayedFormsString = window.localStorage.getItem(LOCALSTORAGE_HUBSPOT_IDS)
            displayedForms = JSON.parse(displayedFormsString)
            // eslint-disable-next-line no-empty
        } catch (e) {}
        if (!Array.isArray(displayedForms)) {
            displayedForms = []
        }
        displayedForms.push(this.el.id)
        try {
            window.localStorage.setItem(LOCALSTORAGE_HUBSPOT_IDS, JSON.stringify(displayedForms))
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }


    registerListeners() {
        const form = this.el.querySelector("form")
        if (!form) {
            return
        }
        form.addEventListener('submit', this.onFormSubmit.bind(this))
    }

    onFormSubmit(event) {
        event.preventDefault()

        const submitButton = this.el.querySelector(CLASSES.SUBMIT_BUTTON)
        submitButton.disabled = true

        const formData = new FormData(event.target)
        formData.set('csrfmiddlewaretoken', getCookie('csrftoken'))

        axios.post(GLOBAL_CONSTANTS.URLS.HUBSPOT_FORM_POST_URL, formData)
            .then((response) => {
                // Handle a successful query
                if (response.status === 200) {
                    const form = this.el.querySelector("form")
                    form.classList.add(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)

                    setTimeout(() => {
                        const domParser = new DOMParser()
                        const parsedDocument = domParser.parseFromString(response.data, 'text/html')

                        form.innerHTML = parsedDocument.body.firstChild.innerHTML

                        const formSuccess = this.el.querySelector(CLASSES.SUCCESS)
                        formSuccess.classList.remove(GLOBAL_CONSTANTS.CLASSES.NOT_SHOWING)
                        formSuccess.classList.add(GLOBAL_CONSTANTS.CLASSES.SHOWING)

                    }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)

                    // Remember that the form was submitted, so the modal won't show anymore
                    this.setSubmitted()
                }
            })
            .catch((error) => {
                // Handle an error
                submitButton.disabled = false
                if (error.response.status === 400) {
                    const domParser = new DOMParser()
                    const parsedDocument = domParser.parseFromString(error.response.data, 'text/html')

                    const form = this.el.querySelector("form")
                    form.innerHTML = parsedDocument.querySelector("form").innerHTML
                } else {
                    Emitter.emit('exception', {
                        error,
                        req: GLOBAL_CONSTANTS.URLS.HUBSPOT_FORM_POST_URL
                    })
                }
            })
    }

}

export const HubspotFormComponent = {
    'name': 'HubspotForm',
    'class': CLASSES.COMPONENT,
    'Source': HubspotForm
}
