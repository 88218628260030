import { GLOBAL_CONSTANTS } from './constants'

export function addSupportClass(pseudoClass) {
    document.body.classList.add(`no-support-${pseudoClass.replace(':', '')}`)
    const inputWrappers = document.querySelectorAll(GLOBAL_CONSTANTS.FORM.WRAPPER)
    inputWrappers.forEach(instance => {
        const input = instance.querySelector(GLOBAL_CONSTANTS.FORM.INPUT)
        const label = instance.querySelector(GLOBAL_CONSTANTS.FORM.LABEL)
        if (label) {
            input.placeholder = label.innerHTML
        }
    })
}

export function testSelector(pseudoClass) {
    try {
        document.querySelector(pseudoClass)
        return true
    } catch (e) {
        return false
    }
}

export default function supportsPseudo (pseudoClass) {
    const isSupported = testSelector(pseudoClass)
    if (!isSupported) {
        addSupportClass(pseudoClass)
    }
}