import { GLOBAL_CONSTANTS } from '../utils/constants'

const SELECTORS = {
    COMPONENT: '.js-notification-bar-wrapper',
    BAR: '.js-notification-bar',
    CLOSE: '.js-notification-bar-close',
    ACCEPT_BUTTON: '.js-accept-cookie-button'
}

export default class NotificationBar {
    /**
     * @desc Set up Notification Bar with elements and bind events.
     * @param {HTMLElement} el - Container element for Link List
     *
     */

    constructor(element) {
        this.element = element
        this.allBarEls = this.element.querySelectorAll(SELECTORS.BAR)
        this.barInstanceMap = {}
        this.httpRequest

        this.generateItemMap()
        this.registerListeners()

        setTimeout(() => {
            this.showBars()
        }, GLOBAL_CONSTANTS.TIMING.NOTIFICATION_BAR_WAIT)
    }

    showBars() {
        this.element.classList.add('--visible')
    }

    generateItemMap() {
        this.allBarEls.forEach((item, i) => {
            this.barInstanceMap[i] = {
                index: i,
                el: item,
                closeBtn: item.querySelector(SELECTORS.CLOSE),
                acceptButton: item.querySelector(SELECTORS.ACCEPT_BUTTON)
            }
        })
    }

    registerListeners() {
        for (let key in this.barInstanceMap) {
            const item = this.barInstanceMap[key]
            item.closeBtn.addEventListener('click', () => {
                this.hideBar(item)
            })

            if (item.acceptButton) {
                item.acceptButton.addEventListener('click', () => {
                    this.setGdprCookie()
                    this.hideBar(item)
                })
            }
        }
    }

    setGdprCookie() {
        this.httpRequest = new XMLHttpRequest()
        this.httpRequest.open('GET', '?gdpr_accepted=1')
        this.httpRequest.send()
    }

    hideBar(bar) {
        const containerHeight = this.element.getBoundingClientRect().height
        const innerTop = bar.el.offsetTop
        const travelDistance = containerHeight - innerTop

        for (let key in this.barInstanceMap) {
            const item = this.barInstanceMap[key]
            if (item.index <= bar.index && !item.el.classList.contains('--hidden')) {
                item.el.style.transform = `translateY(${travelDistance}px)`
            }
        }

        bar.el.classList.add('--hidden')

        setTimeout(() => {
            bar.el.style.display = 'none'
        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NotificationBarComponent = {
    'name': 'NotificationBar',
    'class': SELECTORS.COMPONENT,
    'Source': NotificationBar
}