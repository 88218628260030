import { GLOBAL_CONSTANTS } from '../utils/constants'

const CLASSES = {
    BANNER: '.banner'
}

export default class Banner {
    constructor(el) {
        this.el = el

        this.banner = this.el

        this.loadScripts()
    }

    isDismissed() {
        return window.sessionStorage.getItem('banner-dismissed')
    }

    isColor(strColor) {
        const s = new Option().style
        s.color = strColor
        return s.color !== ''
    }

    loadScripts() {

        if (!this.isDismissed() && window.bannerProperties.show) {
            let container = document.querySelector('.content-container')
            let $color = '#FFFFFF'

            if (this.isColor(window.bannerProperties.color)) {
                $color = window.bannerProperties.color
            }

            let closeSVG = `<button class="close-icon" aria-label="Close Alert Banner">
             <svg width="20" height="20px" viewBox="0 0 22 20" fill="${$color}" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.72 10.766l7.456 7.443 1.061-1.06-7.455-7.442 7.455-7.443-1.061-1.06-7.456 7.443-7.483-7.47-1.061 1.06 7.483 7.47-7.483 7.47 1.061 1.06 7.483-7.47z" fill="#fff"/>
             </svg>
             </button>`

            let $close = document.createElement("div")
            $close.classList.add("e-close")
            $close.addEventListener("click", function() {
                window.sessionStorage.setItem('banner-dismissed', true)
                this.banner.classList.remove('m-active')
                this.banner.classList.add('hide')

                setTimeout(() => {
                    this.banner.style.display = 'none'
                    container.style.marginTop = 'initial'
                }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
            }.bind(this))

            $close.innerHTML = closeSVG

            // background color
            if (this.isColor(window.bannerProperties.background)) {
                this.banner.style.background = window.bannerProperties.background
            }

            // text color
            if (this.isColor(window.bannerProperties.color)) {
                this.banner.style.color = window.bannerProperties.color
            }

            let bannerContent = document.querySelector('.banner-content')
            bannerContent.innerHTML = window.bannerProperties.text

            let bannerInner = document.querySelector('.banner-inner')
            bannerInner.appendChild($close)

            let anchors = document.querySelectorAll('.banner-inner a')

            // following the links should disable the banner
            anchors.forEach(anchor => {
                anchor.addEventListener("click", function() {
                    window.sessionStorage.setItem('banner-dismissed', true)
                    this.banner.classList.remove('m-active')
                    this.banner.classList.add('hide')

                    setTimeout(() => {
                        this.banner.style.display = 'none'
                        container.style.marginTop = 'initial'
                    }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
                }.bind(this))
            })

            setTimeout(function () {
                this.banner.classList.add("m-active")
            }.bind(this), GLOBAL_CONSTANTS.TIMING.STD_ANIM_TIME)
        } else if (this.isDismissed()) {
            this.banner.style.display = 'none'
        } else if (!window.bannerProperties.show) {
            this.banner.style.display = 'none'
        }
    }
}

export const BannerComponent = {
    'name': 'Banner',
    'class': CLASSES.BANNER,
    'Source': Banner
}