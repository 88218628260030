import handorgel from "handorgel"


const CLASSES = {
    COMPONENT: '.js-collapsible'
}

export default class CollapsibleBlock {
    constructor(element) {
        this.element = element

        this.init()
    }

    init() {
        console.log("INIT", handorgel)
        this.element.querySelectorAll(".handorgel").forEach(e => new handorgel(e))
    }
}

export const CollapsibleBlockComponent = {
    'name': 'CollapsibleBlock',
    'class': CLASSES.COMPONENT,
    'Source': CollapsibleBlock
}
