import { CarouselComponent as Carousel } from './Carousel'
import { GLOBAL_CONSTANTS } from '../utils/constants'
import MicroModal from '../utils/MicroModal'
import Emitter from '../utils/emitter'
import axios from 'axios'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import getCookie from '../utils/getCookie'

const CLASSES = {
    COMPONENT: '.js-modal',
    CAROUSEL: '.js-carousel-modal-wrapper',
    YOUTUBE_PLAYER: '.youtube-player',
    MODAL_CTA: '.js-modal-cta',
    MODAL_INFO_WRAPPER: '.js-modal-info-wrapper',
    FORM_CANCEL: '.js-form-cancel',
    FORM_WRAPPER: '.js-form-wrapper',
    MODAL_FORM: '.js-modal-form',
    MODAL_FORM_INPUT_WRAPPER: '.js-modal-form-input-wrapper',
    FORM_INPUT: '.js-form-input',
    ROOT_CONTENT_CONTAINER: '.js-content-container'
}

export default class ArtworkModal {
    constructor(el) {
        this.el = el

        /**
         * Move the modal element to the bottom of the DOM so it can appear over top of everything else.
         */
        const rootContainer = document.querySelector(CLASSES.ROOT_CONTENT_CONTAINER)
        rootContainer.appendChild(this.el)

        this.videoPlayerEl = this.el.querySelector(CLASSES.YOUTUBE_PLAYER)
        this.carouselEl = this.el.querySelector(CLASSES.CAROUSEL)
        this.modalCTA = this.el.querySelector(CLASSES.MODAL_CTA)

        this.modalInfoWrapper = this.el.querySelector(CLASSES.MODAL_INFO_WRAPPER)
        this.modalForm = this.el.querySelector(CLASSES.MODAL_FORM)
        this.modalFormInputWrapper = this.el.querySelector(CLASSES.MODAL_FORM_INPUT_WRAPPER)
        this.formCancelButton = this.el.querySelector(CLASSES.FORM_CANCEL)
        this.formWrapper = this.el.querySelector(CLASSES.FORM_WRAPPER)

        this.initializeVideoConfig = this.initializeVideoConfig.bind(this)
        this.toggleModalForm = this.toggleModalForm.bind(this)
        this.onFormSubmit = this.onFormSubmit.bind(this)

        this.init()
        this.registerListeners()
    }

    init() {
        this.triggers = document.querySelectorAll(`[data-micromodal-trigger=${this.el.id}]`)

        this.modal = new MicroModal({
            disableScroll: true,
            disableFocus: false,
            awaitCloseAnimation: true,
            targetModal: this.el.id,
            triggers: this.triggers,
            onShow: modal => this.onShow(modal),
            onClose: modal => this.onClose(modal)
        })

        this.loadScripts()
    }

    registerListeners() {

        if (this.formCancelButton) {
            this.formCancelButton.addEventListener('click', this.toggleModalForm)
        }

        if (this.modalForm) {
            this.modalForm.addEventListener('submit', this.onFormSubmit)
        }

        if (this.modalCTA) {
            this.modalCTA.addEventListener('click', this.toggleModalForm)
        }
    }

    generateScript(URL, callback) {
        const tag = document.createElement('script')
        tag.onload = callback
        tag.src = URL

        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    loadScripts() {
        if (this.videoPlayerEl && !window.YT) {
            window.onYouTubeIframeAPIReady = () => {
                Emitter.emit('onYouTubeIframeAPIReady')
            }

            Emitter.on('onYouTubeIframeAPIReady', () => {
                this.initializeVideoConfig()
            })

            this.generateScript(GLOBAL_CONSTANTS.URLS.YOUTUBE_API_URL)
        }
    }

    closeAllModals() {
        const openModalId = document.querySelector('.modal.is-open').id
        if (openModalId) {
            MicroModal.close(openModalId)
        }
    }

    onFormSubmit(event) {
        event.preventDefault()

        const formData = new FormData(event.target)
        formData.set('csrfmiddlewaretoken', getCookie('csrftoken'))

        axios.post(GLOBAL_CONSTANTS.URLS.ARTWORK_FORM_POST_URL, formData)
            .then((response) => {
                if (response.status === 200) {
                    const domParser = new DOMParser()
                    const parsedDocument = domParser.parseFromString(response.data, 'text/html')
                    this.formWrapper.innerHTML = parsedDocument.body.firstChild.innerHTML
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    const domParser = new DOMParser()
                    const parsedDocument = domParser.parseFromString(error.response.data, 'text/html')
                    this.modalFormInputWrapper.innerHTML = parsedDocument.querySelector(CLASSES.MODAL_FORM_INPUT_WRAPPER).innerHTML
                } else {
                    Emitter.emit('exception', {
                        error,
                        req: GLOBAL_CONSTANTS.ARTWORK_FORM_POST_URL
                    })
                }
            })
    }

    toggleModalForm() {
        this.modalInfoWrapper.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.modalInfoWrapper.querySelector(CLASSES.FORM_INPUT).focus()

    }

    onShow(modal) {
        /**
         * Disabled the default method on the MicroModal class as it was conflicting
         * with the body-scroll-lock package, which does a better job.
         * Our carousel component is still passing through touch events to the background
         * in some cases.
         */
        this.modal = modal.querySelector('.modal__container')
        disableBodyScroll(this.modal)
        /**
         * If modal contains a YouTube video.
         */
        if (this.videoPlayerEl) {
            this.player.playVideo()
        }

        if (this.carouselEl) {
            this.carouselInstance = new Carousel.Source(this.carouselEl)
        }

    }

    onClose() {
        enableBodyScroll(this.modal)
        if (this.player) {
            this.player.stopVideo()
        }

        if (this.carouselInstance) {
            this.carouselInstance.instance.destroy(true)
        }

        if (this.modalInfoWrapper) {
            this.modalInfoWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }


    initializeVideoConfig() {
        this.videoID = this.videoPlayerEl.dataset.id
        this.playerConfig = {
            videoId: this.videoID,
            playerVars: {
                autoplay: 0, // Auto-play the video on load
                controls: 1, // Show pause/play buttons in player
                showinfo: 0, // Hide the video title
                modestbranding: 1, // Hide the Youtube Logo
                rel: 0, // Only show related videos from the same channel. See ticket #188300092
                fs: 1, // Hide the full screen button
                // Hide closed captions
                cc_load_policy: 0, // eslint-disable-line camelcase
                // Hide the Video Annotations
                iv_load_policy: 3, // eslint-disable-line camelcase
                autohide: 0 // Hide video controls when playing
            },
            events: {
                onReady: function () { }
            }
        }

        this.player = new YT.Player(this.videoPlayerEl, this.playerConfig)
    }

}

export const ArtworkModalComponent = {
    'name': 'Artwork Modal',
    'class': CLASSES.COMPONENT,
    'Source': ArtworkModal
}
