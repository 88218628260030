const SELECTORS = {
    COMPONENT: '.js-read-more-wrapper',
    READ_MORE_BTN: '.js-read-more-btn',
    PREVIEW_TEXT: ".preview-text",
    RICH_TEXT: '.rich-text'
}

export default class ReadMoreButton {

    /**
     * @desc Read more functionality for body & two column
     */


    constructor(element) {
        this.element = element
        this.readMoreBtn = this.element.querySelector(SELECTORS.READ_MORE_BTN)
        this.richText = this.element.querySelector(SELECTORS.RICH_TEXT)

        this.initialize()
    }

    initialize() {
        this.hideCopy()

        this.readMoreBtn.addEventListener('click', () => {
            this.showCopy()
        })

        // #186377100: Set the text of the Read More button
        const previewTextElement = this.element.querySelector(SELECTORS.PREVIEW_TEXT)
        if (previewTextElement?.dataset.preview) {
            this.readMoreBtn.innerText = previewTextElement.dataset.preview
        } else {
            const h1 = document.querySelector("h1")
            if (h1) {
                const previewText = `Read more about ${h1.innerText}`
                this.readMoreBtn.innerText = previewText
            }
        }
    }

    hideCopy() {
        var previewTextSeen = false
        this.richText.children.forEach((item) => {
            if (previewTextSeen) {
                item.classList.add('hide-copy')
                this.readMoreBtn.style.display = 'block'
            }

            if (item.classList.contains('preview-text')) {
                previewTextSeen = true
            }
        })
    }

    showCopy() {
        this.element.querySelectorAll('.hide-copy').forEach((el) => {
            el.classList.remove('hide-copy')
        })

        this.readMoreBtn.style.display = 'none'
    }
}



export const ReadMoreButtonComponent = {
    'name': 'ReadMoreButton',
    'class': SELECTORS.COMPONENT,
    'Source': ReadMoreButton
}