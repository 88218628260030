import { GLOBAL_CONSTANTS } from '../utils/constants'

const SELECTORS = {
    COMPONENT: '.js-load-more-button'
}

export default class LoadMoreButton {
    /**
     * @desc Set up Load More Button with elements and event listeners.
     */

    constructor(element) {
        this.element = element
        this.loadMotion = document.querySelector('.js-load-more-motion')
        this.listWrapper = document.querySelector('.index-list-wrapper')
        this.registerListeners()
    }

    registerListeners() {
        this.element.addEventListener('click', this.loadMoreResults.bind(this))
    }

    loadMoreResults(event) {
        this.element.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.loadMotion.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        event.preventDefault()

        var xhr = new XMLHttpRequest()
        xhr.open('GET', event.target.value, true)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const domParser = new DOMParser()
                const parsedDocument = domParser.parseFromString(xhr.response, 'text/html')
                const newItems = parsedDocument.querySelectorAll('.js-index-grid-list-item')

                newItems.forEach(item => {
                    item.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                    this.listWrapper.appendChild(item)
                })

                this.loadMotion.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)

                // Show the button again if there are more pages
                const pageElements = document.querySelectorAll('.index-list-item-wrapper')
                const lastPage = pageElements[pageElements.length - 1]
                if (lastPage.dataset.page) {
                    event.target.value = lastPage.dataset.page
                    this.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
                }
            }
        }
        xhr.send()
    }
}

/**
 * @desc component definition used in module-loader
 */

export const LoadMoreButtonComponent = {
    'name': 'LoadMoreButton',
    'class': SELECTORS.COMPONENT,
    'Source': LoadMoreButton
}