/**
 * Adapted from https://github.com/kwdowik/zoom-pan
 * Verion: 1.0.0
 */

const hasPositionChanged = ({ pos, prevPos }) => pos !== prevPos

const valueInRange = ({ minScale, maxScale, scale }) => scale <= maxScale && scale >= minScale

const getTranslate = ({ minScale, maxScale, scale }) => ({ pos, prevPos, translate }) =>
    valueInRange({ minScale, maxScale, scale }) && hasPositionChanged({ pos, prevPos })
        ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
        : translate

const getScale = ({ scale, minScale, maxScale, scaleSensitivity, deltaScale }) => {
    let newScale = scale + (deltaScale / (scaleSensitivity / scale))
    newScale = Math.max(minScale, Math.min(newScale, maxScale))
    return [scale, newScale]
}

const getMatrix = ({ scale, translateX, translateY }) => `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`

const pan = ({ state, originX, originY }) => {
    state.transformation.translateX += originX
    state.transformation.translateY += originY

    const {top, bottom, left, right} = state.element.getBoundingClientRect()
    const imageWidth = right - left
    const imageHeight = bottom - top

    //prevent image from escaping bounds of the screen
    //check if image width exceeds screen width
    if (imageWidth > window.innerWidth) {
        //if image is being pulled to the right, from the left side of window
        if (left > 0) {
            //prevent image from showing whitespace on the left
            state.transformation.translateX -= left
        }
        //if image is being pulled to the left, from the right side of window
        if (right < window.innerWidth) {
            //prevent image from showing whitespace on right
            state.transformation.translateX += window.innerWidth - right
        }
    //if image width is smaller than scrreen width
    } else {
        //if image is being pulled to the right or left, undo transform to center it
        if (left > 0 || right < window.innerWidth) {
            state.transformation.translateX = 0
        }
    }

    //check if image height exceeds screen height
    if (imageHeight > window.innerHeight) {
        //if image is being pulled to the bottom, from the top of window
        if (top > 0) {
            //prevent image top from being pulled downward past window top
            state.transformation.translateY -= top
        }
        //if image is being pulled to the top, from the bottom of window
        if (bottom < window.innerHeight) {
            //prevent image bottom from being pulled upward past window bottom
            state.transformation.translateY += window.innerHeight - bottom
        }
    //if image height is smaller than screen height
    } else {
        //if image is being pulled to the top or bottom, undo transform to center it
        if (top > 0 || bottom < window.innerHeight) {
            state.transformation.translateY = 0
        }
    }

    state.element.style.transform =
        getMatrix({ scale: state.transformation.scale, translateX: state.transformation.translateX, translateY: state.transformation.translateY })
}

const makePan = (state) => {
    return {
        panBy: ({ originX, originY }) => pan({ state, originX, originY }),
        panTo: ({ originX, originY, scale }) => {
            state.transformation.scale = scale
            pan({ state, originX: originX - state.transformation.translateX, originY: originY - state.transformation.translateY })
        }
    }
}

const makeZoom = (state) => ({
    zoom: ({ x, y, deltaScale }) => {
        const { left, top } = state.element.getBoundingClientRect()
        const { minScale, maxScale, scaleSensitivity } = state
        const [ scale, newScale ] = getScale({ scale: state.transformation.scale, deltaScale, minScale, maxScale, scaleSensitivity })
        const originX = x - left
        const originY = y - top
        const newOriginX = originX / scale
        const newOriginY = originY / scale
        const translate = getTranslate({ scale, minScale, maxScale })
        const translateX = translate({ pos: originX, prevPos: state.transformation.originX, translate: state.transformation.translateX })
        const translateY = translate({ pos: originY, prevPos: state.transformation.originY, translate: state.transformation.translateY })

        state.element.style.transformOrigin = `${newOriginX}px ${newOriginY}px`
        state.element.style.transform = getMatrix({ scale: newScale, translateX, translateY })
        state.transformation = { originX: newOriginX, originY: newOriginY, translateX, translateY, scale: newScale }
    }
})

const renderer = ({ minScale, maxScale, element, scaleSensitivity = 10 }) => {
    const state = {
        element,
        minScale,
        maxScale,
        scaleSensitivity,
        transformation: {
            originX: 0,
            originY: 0,
            translateX: 0,
            translateY: 0,
            scale: 1
        }
    }
    return Object.assign({}, makeZoom(state), makePan(state))
}

export { renderer }